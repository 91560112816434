import { Component, OnInit, OnDestroy } from '@angular/core';
import { Stock } from 'src/app/models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { StockService } from 'src/app/stock.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-stock',
  templateUrl: './edit-stock.component.html',
  styleUrls: ['./edit-stock.component.scss']
})
export class EditStockComponent implements OnInit, OnDestroy {

  stock: Stock;
  form: FormGroup;
  stockSub: Subscription;
  saveStockSub: Subscription = null;
  constructor(private stockService: StockService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindStock();
  }

  bindStock() {
    this.spinner.show();
    this.stockSub = this.stockService.getById(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.stock = res;
      if (res != null) {
        this.form.patchValue({
          stock: this.stock.physicalStock,
          color: this.stock.color,
          size: this.stock.size
        });
      }
      console.log(this.stock);
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please reload the page again...');
    });
  }

  createForm() {
    this.form = this.fb.group({
      stock: ['', Validators.required],
      color: null,
      size: null
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveStock() {
    this.spinner.show();
    this.stock.physicalStock = this.form.value.stock;
    this.stock.color = this.form.value.color;
    this.stock.size = this.form.value.size;

    this.saveStockSub = this.stockService.updateStock(this.stock).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/admin/products']);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.saveStockSub != null) {
      this.saveStockSub.unsubscribe();
    }

    this.stockSub.unsubscribe();
  }

}
