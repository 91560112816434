import { BaseApiService } from './../../base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SlidersService } from 'src/app/sliders.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Slider } from 'src/app/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sliders',
  templateUrl: './sliders.component.html',
  styleUrls: ['./sliders.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SlidersComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  sliders: Slider[] = [];
  slidersSub: Subscription;
  deleteSliderSub: Subscription;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Slider>(this.sliders);
  displayedColumns: string[] = ['imagePath', 'displayOrder', 'actionEdit', 'actionDelete'];
  constructor(private slidersService: SlidersService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindSliders();
  }

  bindSliders() {
    this.spinner.show();
    this.slidersSub = this.slidersService.getSliders().subscribe(res => {
      this.sliders = res;
      this.dataSource = new MatTableDataSource<Slider>(this.sliders);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteSlider(id: number) {
    const deleted = confirm('Are you sure you want to delete this slider?');
    if (deleted) {
      this.spinner.show();
      this.deleteSliderSub = this.slidersService.deleteSlider(id).subscribe(res => {
        alert('Slider deleted successfully.');
        this.bindSliders();
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        console.log(error);
        this.spinner.hide();
      });
    }

  }

  ngOnDestroy() {
    if (this.deleteSliderSub != null) {
      this.deleteSliderSub.unsubscribe();
    }
    this.slidersSub.unsubscribe();
  }

}
