import { Contact } from './../../models';
import { ContactService } from './../../contact.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  contacts: Contact[] = [];
  contactsSub: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Contact>(this.contacts);
  displayedColumns: string[] = ['name', 'email', 'mobile', 'details'];

  constructor(private contactService: ContactService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindContacts();
  }

  bindContacts() {
    this.spinner.show();
    this.contactsSub = this.contactService.getContacts().subscribe(res => {
      this.contacts = res;
      this.dataSource = new MatTableDataSource<Contact>(this.contacts);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.contactsSub.unsubscribe();
  }

}
