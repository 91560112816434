import { NgxSpinnerService } from 'ngx-spinner';
import { Menu } from './../../models';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MenuService } from 'src/app/menu.service';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menus',
  templateUrl: './menus.component.html',
  styleUrls: ['./menus.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class MenusComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  menus: Menu[] = [];
  menusSub: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Menu>(this.menus);
  displayedColumns: string[] = ['menuName', 'menuOrder', 'actionCategories', 'actionEdit', 'actionDelete'];

  constructor(private menuService: MenuService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindMenus();
  }

  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.dataSource = new MatTableDataSource<Menu>(this.menus);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteMenu(id: number) {
    const deleted = confirm('Are you sure you want to delete this menu?');
    if (deleted) {
      this.spinner.show();
      this.menuService.deleteMenu(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert('Menu deleted successfully.');
          this.bindMenus();
        } else {
          alert(res.message);
        }
      }, error => {
        this.spinner.hide();
        if (error instanceof HttpErrorResponse) {
          if (error.status == 401) {
            this.router.navigate(['/sign-in']);
          } else {
            alert('Error occured. Please try again...');
          }
        } else {
          alert('Error occured. Please try again...');
        }
      });
    }
  }

  ngOnDestroy() {
    this.menusSub.unsubscribe();
  }

}
