import { BaseApiService } from './../../base-api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {TopCategoryService} from 'src/app/top-category.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Slider, TopCategory } from 'src/app/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-top-categories',
  templateUrl: './top-categories.component.html',
  styleUrls: ['./top-categories.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class TopCategoriesComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  topCategories: TopCategory[] = [];
  topCategoriesSub: Subscription;
  deleteTopCategorySub: Subscription;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<TopCategory>(this.topCategories);
  displayedColumns: string[] = ['imagePath', 'displayOrder', 'displayName', 'categoryID', 'actionEdit', 'actionDelete'];
  constructor(private topCategoryService: TopCategoryService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindTopCategories();
    // getTopCategories
  }

  bindTopCategories() {
    this.spinner.show();
    this.topCategoriesSub = this.topCategoryService.getTopCategories().subscribe(res => {
      console.log(res);
      this.topCategories = res;
      this.dataSource = new MatTableDataSource<TopCategory>(this.topCategories);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteTopCategory(id: number) {
    const deleted = confirm('Are you sure you want to delete this Top Category?');
    if (deleted) {
      this.spinner.show();
      this.deleteTopCategorySub = this.topCategoryService.deleteTopCategory(id).subscribe(res => {
        alert('Top Category deleted successfully.');
        this.bindTopCategories();
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        console.log(error);
        this.spinner.hide();
      });
    }

  }

  ngOnDestroy() {
    if (this.deleteTopCategorySub != null) {
      this.deleteTopCategorySub.unsubscribe();
    }
    this.topCategoriesSub.unsubscribe();
  }

}
