import { NgxSpinnerService } from 'ngx-spinner';
import { CategoryService } from './../../category.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApiService } from 'src/app/base-api.service';
import { Category, Menu } from 'src/app/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit, OnDestroy {
  baseApiUrl = BaseApiService.baseApiUrl;
  categories: Category[] = [];
  categoriesSub: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Category>(this.categories);
  displayedColumns: string[] = ['categoryName', 'categoryOrder', 'actionSubCategories', 'actionEdit', 'actionDelete'];

  constructor(private categoryService: CategoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindCategories();
  }

  bindCategories() {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.categories = res;
      this.dataSource = new MatTableDataSource<Category>(this.categories);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteCategory(id: number) {
    const deleted = confirm('Are you sure you want to delete this Category?');
    if (deleted) {
      this.spinner.show();
      this.categoryService.deleteCategory(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert(res.message);
          this.bindCategories();
        } else {
          alert(res.message);
        }
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }
  }

  ngOnDestroy() {
    this.categoriesSub.unsubscribe();
  }

  onClickNewCategory() {
    this.router.navigate(['/admin/add-category/' + (+this.activatedRoute.snapshot.params.id)]);
  }

}
