import { AppService } from './../../app.service';
import { Title } from '@angular/platform-browser';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { Subject, interval } from 'rxjs';
import { takeUntil, map } from 'rxjs/operators';
import { UsersService } from 'src/app/users.service';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit, OnDestroy {
  notificationsSub: Subscription;
  destroyed = new Subject<void>();
  @ViewChild(RouterOutlet) outlet: RouterOutlet;
  constructor(private router: Router, private titleService: Title, public appService: AppService,
    private notificationService: NotificationService, private spinner: NgxSpinnerService,
    private userService: UsersService) { }

  ngOnInit(): void {
    this.validateUser();
    this.titleService.setTitle('Admin Dashboard - ' + this.appService.Data.title);
    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart) {
        this.outlet.deactivate();
      }
    });
    this.getNofications(true);
    this.startInterval();
  }


  startInterval() {
    // console.log('startig');
    interval(10 * 1000)
      .pipe(
        takeUntil(this.destroyed),
        map(() => this.getNofications(false))
      ).subscribe();
  }


  getNofications(firstTime) {
    this.notificationService.getUnreadNotificationsCountForUser('admin').subscribe(res => {
      this.appService.Data.unreadNotificationsCount = res;
      const audio = new Audio('https://api.jhelumcart.com/notification.mp3');
      if (firstTime) {
        this.appService.Data.unreadNotificationsCountPrevious = res;
      }
      if (!firstTime) {
        if (this.appService.Data.unreadNotificationsCountPrevious !== this.appService.Data.unreadNotificationsCount) {
          audio.play();
          this.appService.Data.unreadNotificationsCountPrevious = this.appService.Data.unreadNotificationsCount;
        }
      }
    }, err => {
    });
  }
  gotoNotifications() {
    this.router.navigate(['/admin/notifications']);
  }

  logout() {
    localStorage.removeItem('token');
    this.router.navigate(['/sign-in']);
  }

  ngOnDestroy() {
    this.destroyed.next();
  }

  validateUser() {
    this.spinner.show();
    this.userService.validateUser().subscribe(res => {
      this.spinner.hide();
      if (!res.added) {
        localStorage.removeItem('token');
        this.router.navigateByUrl('/sign-in');
      }
    }, err => {
      this.spinner.hide();
      localStorage.removeItem('token');
      this.router.navigateByUrl('/sign-in');
    });
  }

}
