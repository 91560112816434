import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { AdminRoutingModule } from './admin-routing.module';
import { MaterialModule } from '../material/material.module';
import { SlidersComponent } from './sliders/sliders.component';
import { ProductsComponent } from './products/products.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AddSliderComponent } from './add-slider/add-slider.component';
import { HttpClientModule } from '@angular/common/http';
import { EditSliderComponent } from './edit-slider/edit-slider.component';
import { SiteSettingsComponent } from './site-settings/site-settings.component';
import { MenusComponent } from './menus/menus.component';
import { AddMenuComponent } from './add-menu/add-menu.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';
import { CategoriesComponent } from './categories/categories.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { AddSubcategoryComponent } from './add-subcategory/add-subcategory.component';
import { EditSubcategoryComponent } from './edit-subcategory/edit-subcategory.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { HomepageHeadingsComponent } from './homepage-headings/homepage-headings.component';
import { AddHomepageHeadingComponent } from './add-homepage-heading/add-homepage-heading.component';
import { EditHomepageHeadingComponent } from './edit-homepage-heading/edit-homepage-heading.component';
import { HomepageProductsComponent } from './homepage-products/homepage-products.component';
import { AddHomepageProductComponent } from './add-homepage-product/add-homepage-product.component';
import { AddProductComponent } from './add-product/add-product.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { StockRegisterComponent } from './stock-register/stock-register.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { EditStockComponent } from './edit-stock/edit-stock.component';
import { StocksComponent } from './stocks/stocks.component';
import { ProductAttributesComponent } from './product-attributes/product-attributes.component';
import { AddProductAttributeComponent } from './add-product-attribute/add-product-attribute.component';
import { EditProductAttributeComponent } from './edit-product-attribute/edit-product-attribute.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { PincodesComponent } from './pincodes/pincodes.component';
import { AddPincodeComponent } from './add-pincode/add-pincode.component';
import { EditPincodeComponent } from './edit-pincode/edit-pincode.component';
import { SellersComponent } from './sellers/sellers.component';
import { SellerDetailsComponent } from './seller-details/seller-details.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { ColorsComponent } from './colors/colors.component';
import { AddColorComponent } from './add-color/add-color.component';
import { EditColorComponent } from './edit-color/edit-color.component';
import { AdminProductImagesComponent } from './admin-product-images/admin-product-images.component';
import { NgImageFullscreenViewModule } from 'ng-image-fullscreen-view';
import { LogisticPartnersComponent } from './logistic-partners/logistic-partners.component';
import { LogisticPartnerDetailsComponent } from './logistic-partner-details/logistic-partner-details.component';
import { BannersComponent } from './banners/banners.component';
import { AddBannerComponent } from './add-banner/add-banner.component';
import { EditBannerComponent } from './edit-banner/edit-banner.component';
import { ProductInfoComponent } from './product-info/product-info.component';
import { TopCategoriesComponent } from './top-categories/top-categories.component';
import { AddTopCategoryComponent } from './add-topcategory/add-topcategory.component';
import { EditTopCategoryComponent } from './edit-topcategory/edit-topcategory.component';
import { SellerRegisterComponent } from './seller-register/seller-register.component';
import { SellerReceiptComponent } from './seller-receipt/seller-receipt.component';
import { PincodeSlabsComponent } from './pincode-slabs/pincode-slabs.component';
import { AddPincodeSlabComponent } from './add-pincode-slab/add-pincode-slab.component';
import { EditPincodeSlabComponent } from './edit-pincode-slab/edit-pincode-slab.component';
import { EditHomepageProductComponent } from './edit-homepage-product/edit-homepage-product.component';
import { TimeAgo } from '../time-ago.pipe';
import { CustomOrdersComponent } from './custom-orders/custom-orders.component';
import { CustomOrderDetailsComponent } from './custom-order-details/custom-order-details.component';
import { RequestOrdersComponent } from './request-orders/request-orders.component';
import { RequestOrderDetailsComponent } from './request-order-details/request-order-details.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { SellerPaymentsComponent } from './seller-payments/seller-payments.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { AddExpenseComponent } from './add-expense/add-expense.component';

@NgModule({
  declarations: [AdminDashboardComponent,
    SlidersComponent,
    ProductsComponent,
    AddSliderComponent,
    EditSliderComponent,
    SiteSettingsComponent,
    MenusComponent,
    LogisticPartnersComponent,
    LogisticPartnerDetailsComponent,
    AddMenuComponent,
    EditMenuComponent,
    CategoriesComponent,
    SubcategoriesComponent,
    AddCategoryComponent,
    AddSubcategoryComponent,
    EditSubcategoryComponent,
    EditCategoryComponent,
    HomepageHeadingsComponent,
    AddHomepageHeadingComponent,
    EditHomepageHeadingComponent,
    HomepageProductsComponent,
    AddHomepageProductComponent,
    AddProductComponent,
    EditProductComponent,
    StockRegisterComponent,
    AddStockComponent,
    EditStockComponent,
    StocksComponent,
    ProductAttributesComponent,
    AddProductAttributeComponent,
    EditProductAttributeComponent,
    OrdersComponent,
    OrderDetailsComponent,
    ContactsComponent,
    ContactDetailsComponent,
    PincodesComponent,
    AddPincodeComponent,
    EditPincodeComponent,
    SellersComponent,
    SellerDetailsComponent,
    NotificationsComponent,
    ColorsComponent,
    AddColorComponent,
    EditColorComponent,
    AdminProductImagesComponent,
    BannersComponent,
    AddBannerComponent,
    ProductInfoComponent,
    EditBannerComponent,
    AddTopCategoryComponent,
    EditTopCategoryComponent,
    SellerRegisterComponent,
    TopCategoriesComponent,
    SellerReceiptComponent,
    PincodeSlabsComponent,
    AddPincodeSlabComponent,
    EditPincodeSlabComponent,
    EditHomepageProductComponent,
    TimeAgo,
    CustomOrdersComponent,
    CustomOrderDetailsComponent,
    RequestOrdersComponent,
    RequestOrderDetailsComponent,
    SellerPaymentsComponent,
    ExpensesComponent,
    AddExpenseComponent],
  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    CKEditorModule,
    NgImageFullscreenViewModule,
    NgxPaginationModule 
  ],
  providers: [
    DatePipe
  ]
})
export class AdminModule { }
