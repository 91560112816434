import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApiService } from 'src/app/base-api.service';
import { Subscription } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-seller-details',
  templateUrl: './seller-details.component.html',
  styleUrls: ['./seller-details.component.scss']
})
export class SellerDetailsComponent implements OnInit, OnDestroy {
  baseApiUrl = BaseApiService.baseApiUrl;
  user: any = null;
  userSub: Subscription;
  activateSub: Subscription = null;
  deactivateSub: Subscription = null;

  productImagesObject: any;
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  currentIndex: number = -1;
  form: FormGroup;
  licenseExpirationDate = '';
  uploadResponse1 = { status: '', message: '', filePath: '' };
  isInProgress1 = false;
  file1: FileUploadModel;
  fileName1 = '';
  error: string;
  sliderImg1Validations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  constructor(private usersService: UsersService, private spinner: NgxSpinnerService,
    private activatedRoute: ActivatedRoute, private router: Router,
    private fb: FormBuilder, private datePipe: DatePipe) {
    this.createForm();
  }

  ngOnInit(): void {
    this.bindUser();
  }

  createForm() {
    this.form = this.fb.group({
      rentAmount: ['', Validators.required],
      rentPeriodInMonths: ['', Validators.required],
      subscriptionModel: ['', Validators.required]
    });
  }

  bindUser() {
    this.productImagesObject = [];
    const username = this.activatedRoute.snapshot.params.username;
    this.spinner.show();
    this.userSub = this.usersService.getUserByUsernameAdmin(username).subscribe(res => {
      this.user = res;

      this.form.patchValue({
        rentAmount: res.rentAmount,
        rentPeriodInMonths: res.rentPeriodInMonths,
        subscriptionModel: res.subscriptionModel
      });

      let imgData1 = {
        image: this.baseApiUrl + this.user.registrationDocumentImagePath,
        thumbImage: this.baseApiUrl + this.user.registrationDocumentImagePath,
        title: 'Registration'
      };
      this.productImagesObject.push(imgData1);

      let imgData2 = {
        image: this.baseApiUrl + this.user.aadharCardImagePath,
        thumbImage: this.baseApiUrl + this.user.aadharCardImagePath,
        title: 'Aadhar'
      };
      this.productImagesObject.push(imgData2);

      let imgData3 = {
        image: this.baseApiUrl + this.user.panCardImagePath,
        thumbImage: this.baseApiUrl + this.user.panCardImagePath,
        title: 'PAN'
      };
      this.productImagesObject.push(imgData3);

      let imgData4 = {
        image: this.baseApiUrl + this.user.businessLogoPath1,
        thumbImage: this.baseApiUrl + this.user.businessLogoPath1,
        title: 'Slider Image 1'
      };
      this.productImagesObject.push(imgData4);

      let imgData5 = {
        image: this.baseApiUrl + this.user.businessLogoPath2,
        thumbImage: this.baseApiUrl + this.user.businessLogoPath2,
        title: 'Slider Image 2'
      };
      this.productImagesObject.push(imgData5);

      let imgData6 = {
        image: this.baseApiUrl + this.user.businessLogoPath3,
        thumbImage: this.baseApiUrl + this.user.businessLogoPath3,
        title: 'Slider Image 3'
      };
      this.productImagesObject.push(imgData6);

      let imgData7 = {
        image: this.baseApiUrl + this.user.businessLogoPath4,
        thumbImage: this.baseApiUrl + this.user.businessLogoPath4,
        title: 'Slider Image 4'
      };
      this.productImagesObject.push(imgData7);

      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  activateSeller() {
    this.spinner.show();
    const user = this.user;
    user.isActive = true;
    user.modifiedBy = 'admin';
    if (this.activateSub != null) {
      this.activateSub.unsubscribe();
    }
    this.activateSub = this.usersService.updateUser(user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        alert('Seller activated successfully.');
        this.bindUser();
      } else {
        alert(res.message);
      }
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  deactivateSeller() {
    this.spinner.show();
    const user = this.user;
    user.isActive = false;
    user.modifiedBy = 'admin';
    if (this.deactivateSub != null) {
      this.deactivateSub.unsubscribe();
    }
    this.deactivateSub = this.usersService.updateUser(user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        alert('Seller deactivated successfully.');
        this.bindUser();
      } else {
        alert(res.message);
      }
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  saveProfile() {
    // console.log('Registration Document:', this.fileNameRegistration);
    // console.log('Pan Card:', this.fileNamePanCard);
    // console.log('Aadhar Card:', this.fileNameAadharCard);

    // return;

    // if (this.fileName1 == '' &&
    //     this.fileName2 == '' &&
    //     this.fileName3 == '' &&
    //     this.fileName4 == '') {
    //       Swal.fire('Error', 'At least one Banner Image is required.');
    //       return;
    //     }
    // if (this.fileNameRegistration == '') {
    //   Swal.fire('Error', 'Registration Document Image is required.');
    //   return;
    // }
    // if (this.fileNameAadharCard == '') {
    //   Swal.fire('Error', 'Aadhar Card Image is required.');
    //       return;
    // }
    // if (this.fileNamePanCard == '') {
    //   Swal.fire('Error', 'PAN Card Image is required.');
    //       return;
    // }


    let username = '';
    username = this.user.username;
    this.user.businessLogoPath1 = this.fileName1 == '' ? '' : 'Seller/' + username + '/Logo/' + this.fileName1;
    this.user.modifiedBy = 'admin';
    this.spinner.show();
    this.usersService.updateUser(this.user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire('Message', 'Images saved successfully.', 'success');
      } else {
        Swal.fire('Error', res.message, 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }

  ngOnDestroy() {
    if (this.activateSub != null) {
      this.activateSub.unsubscribe();
    }
    if (this.deactivateSub != null) {
      this.deactivateSub.unsubscribe();
    }
    this.userSub.unsubscribe();
  }

  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  updateLicenseInfo() {
    this.spinner.show();
    this.user.rentAmount = this.form.value.rentAmount;
    this.user.rentPeriodInMonths = this.form.value.rentPeriodInMonths;
    this.user.subscriptionModel = this.form.value.subscriptionModel;

    this.usersService.updateUser(this.user)
      .subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          Swal.fire('Message', 'License Information updated successfully.', 'success');
          this.bindUser();
        } else {
          Swal.fire('Error', res.message, 'error');
        }
      }, err => {
        this.spinner.hide();
        if (err.status === 401) {
          this.router.navigateByUrl('/');
        } else {
          Swal.fire('Error', err.error.message, 'error');
        }
      });
  }

  updateLicenseExpirationDate() {
    this.spinner.show();
    this.usersService.extendLicenseInfo(this.activatedRoute.snapshot.params.username,
      this.datePipe.transform(this.licenseExpirationDate, 'MM/dd/yyyy'))
      .subscribe(res => {
        this.spinner.hide();
        alert(res.message);
        if (res.added) {
          this.bindUser();
        }
      }, err => {
        if (err.status === 401) {
          localStorage.removeItem('token');
          this.router.navigateByUrl('/');
        } else {
          Swal.fire('Error', err.error.message, 'error');
        }
      });
  }

  onClickImage1() {
    const fileUpload = document.getElementById('fileUpload1') as HTMLInputElement;
    fileUpload.onchange = () => {

      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }


      this.sliderImg1Validations.uploadAttempted = true;
      const file = fileUpload.files[0];

      const mimeType = file.type;
      //  console.log(file.type);   
      // check it is a image format..
      if (mimeType.match(/image\/*/) == null) {
        this.sliderImg1Validations.validType = false;
      }

      // check it is among allowed image formats
      if (!(mimeType == "image/png" || "image/PNG" ||
        mimeType == "image/jpg" || "image/JPG" ||
        mimeType == "image/jpeg" || "image/JPEG" ||
        mimeType == "image/gif")) {
        this.sliderImg1Validations.validType = false;
      }

      // check file size 200kb 
      const fsize = file.size;
      const fileKb = Math.round((fsize / 1024));
      if (fileKb > 200) {
        this.sliderImg1Validations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.sliderImg1Validations.validDimension = (width != 400 || height != 400) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.sliderImg1Validations.validDimension ||
            !this.sliderImg1Validations.validSize ||
            !this.sliderImg1Validations.validType) {

            let errorMsg = "";
            if (!this.sliderImg1Validations.validDimension) {
              errorMsg += "<div>Invalid file dimensions</div>";
            }
            if (!this.sliderImg1Validations.validSize) {
              errorMsg += "<div>Invalid file size</div>";
            }
            if (!this.sliderImg1Validations.validType) {
              errorMsg += "<div>Invalid file type</div>";
            }

            Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            let username = '';
            username = this.user.username;
            formData.append('username', username);
            // console.log(formData);
            this.file1 = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile1(formData);
          }
        };
      };
    };
    fileUpload.click();
  }

  private uploadFile1(formData: FormData) {
    this.usersService.uploadBusinessSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse1 = res;
          this.isInProgress1 = true;
        }
        this.fileName1 = this.file1.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
}
