import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuService } from 'src/app/menu.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Menu } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-menu',
  templateUrl: './edit-menu.component.html',
  styleUrls: ['./edit-menu.component.scss']
})
export class EditMenuComponent implements OnInit, OnDestroy {

  form: FormGroup;
  menu: Menu = null;
  menuSub: Subscription;
  saveMenuSub: Subscription;
  constructor(private menuService: MenuService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindMenu();
  }

  bindMenu() {
    this.spinner.show();
    this.menuSub = this.menuService.getMenu(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.menu = res;
      this.form.patchValue({
        menuName: this.menu.menuName,
        menuOrder: this.menu.menuOrder
      });
      this.spinner.hide();
    }, error => {
      this.menu = null;
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      menuName: ['', Validators.required],
      menuOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveMenu() {
    this.spinner.show();
    if (this.menu != null) {
      this.menu.menuName = this.form.value.menuName;
      this.menu.menuOrder = this.form.value.menuOrder;
      this.saveMenuSub = this.menuService.updateMenu(this.menu).subscribe(res => {
        if (res) {
          alert('Menu saved successfully.');
        } else {
          alert('Error occured. Please try again...');
        }
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }
  }

  ngOnDestroy() {
    this.menuSub.unsubscribe();
    if (this.saveMenuSub != null) {
      this.saveMenuSub.unsubscribe();
    }
  }

}
