import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { AppNotification } from './models';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  /**
   * getUnreadNotificationsForUser
   */
  public getUnreadNotificationsForUser(username: string, pageNumber: number, pageSize: number, query: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Notification/GetUnreadNotificationsForUser?username=' + username +
    '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&query=' + query;

    return this.http.get<any>(url);
  }

  /**
   * getAllNotificationsForUser
   */
  public getAllNotificationsForUser(username: string, pageNumber: number, pageSize: number, query: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Notification/GetAllNotificationsForUser?username=' + username +
    '&pageNumber=' + pageNumber + '&pageSize=' + pageSize + '&query=' + query;

    return this.http.get<any>(url);
  }

  /**
   * getUnreadNotificationsCountForUser
   */
  public getUnreadNotificationsCountForUser(username: string): Observable<number> {
    const url = BaseApiService.baseApiUrl + 'api/Notification/GetUnreadNotificationsCountForUser?username=' + username;

    return this.http.get<number>(url);
  }

  /**
   * markNotificationAsRead
   */
  public markNotificationAsRead(id: string): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/Notification/MarkNotificationAsRead?id=' + id;

    return this.http.put<boolean>(url, null);
  }

  public markAllAsRead(): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/Notification/MarkAllAsRead?username=' +
    username;

    return this.http.put<any>(url, null);
  }
}
