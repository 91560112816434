import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { MenuService } from './../../menu.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Menu } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-menu',
  templateUrl: './add-menu.component.html',
  styleUrls: ['./add-menu.component.scss']
})
export class AddMenuComponent implements OnInit, OnDestroy {

  form: FormGroup;
  addMenuSub: Subscription = null;
  constructor(private menuService: MenuService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      menuName: ['', Validators.required],
      menuOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveMenu() {
    this.spinner.show();
    const menu: Menu = {
      menuName: this.form.value.menuName,
      menuOrder: this.form.value.menuOrder
    };
    this.addMenuSub = this.menuService.addMenu(menu).subscribe(added => {
      if (added) {
        alert('Menu saved successfully.');
        this.router.navigate(['/admin/menus']);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.addMenuSub != null) {
      this.addMenuSub.unsubscribe();
    }
  }

}
