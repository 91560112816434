import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UsersService } from 'src/app/users.service';
import { User } from 'src/app/models';
import { Subscription } from 'rxjs';
import { BaseApiService } from 'src/app/base-api.service';

@Component({
  selector: 'app-sellers',
  templateUrl: './sellers.component.html',
  styleUrls: ['./sellers.component.scss']
})
export class SellersComponent implements OnInit, OnDestroy {

  users: User[] = [];
  usersSub: Subscription;
  totalCount = 0;
  currentPage = 1;
  pageSize = 10;
  businessName = '';
  mobile = '';
  pinCode = '';
  sortBy = 'name';
  direction = 'asc';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<User>(this.users);
  displayedColumns: string[] = ['name', 'mobile', 'businessName', 'pinCode', 'actionDetails', 'actionProducts', 'actionSellerReceipt', 'actionSellerInvoice'];

  constructor(private usersService: UsersService,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindUsers();
  }

  bindUsers() {
    this.spinner.show();
    this.usersSub = this.usersService.getSellers(this.businessName, this.mobile, this.pinCode,
        this.currentPage - 1, this.pageSize,this.sortBy, this.direction).subscribe(res => {
      this.users = res.users;
      this.totalCount = res.totalItems;
      this.dataSource = new MatTableDataSource<User>(this.users);
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  doFilterBusinessName(event) {
    this.businessName = event.target.value;
    this.bindUsers();
  }

  doFilterMobile(event) {
    this.mobile = event.target.value;
    this.bindUsers();
  }
  doFilterPincode(event) {
    this.pinCode = event.target.value;
    this.bindUsers();
  }

  doSorting(event) {
    this.sortBy = event.active;
    this.direction = event.direction;
    this.bindUsers();
  }

  changePage(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.bindUsers();
  }

  ngOnDestroy() {
    this.usersSub.unsubscribe();
  }

  downloadSellerInvoice(username) {
    window.open(BaseApiService.baseApiUrl + "Download/DownloadSellerRentInvoice?username=" + username);
  }

}
