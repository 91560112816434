import { Component, OnInit, ViewChild } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductImageService } from 'src/app/product-image.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductImage } from 'src/app/models';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-admin-product-images',
  templateUrl: './admin-product-images.component.html',
  styleUrls: ['./admin-product-images.component.scss']
})
export class AdminProductImagesComponent implements OnInit {
  productId: string;
  productImages: ProductImage[] = [];
 
  baseApiUrl = BaseApiService.baseApiUrl;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<ProductImage>(this.productImages);
  displayedColumns: string[] = ['imagePath', 'color', 'actionEdit', 'actionDelete'];

  productImagesObject : any;
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  currentIndex : number = -1;

  constructor(private spinner: NgxSpinnerService,
    private productImageService: ProductImageService,
    private router: Router,
    private activatedRoute: ActivatedRoute) {
      this.productId = this.activatedRoute.snapshot.params.id;
     }

  ngOnInit(): void {
    this.bindProductImages();
  }

  bindProductImages() {
    this.spinner.show();
    this.productImageService.getProductImages(+this.productId).subscribe(res => {
      this.productImages = res;
      console.log('Res:', res);
      // baseApiUrl + element.imagePath
 
    this.productImagesObject = [];
    this.productImages.forEach( (element, index) => {
      let imgData = {
        image: this.baseApiUrl + element.imagePath,
        thumbImage: this.baseApiUrl + element.imagePath,
        title: element.color
      };
      this.productImagesObject.push(imgData);
      element['index'] = index;
      this[index] = element;
    },this);
    console.log(this.productImages);
      this.dataSource = new MatTableDataSource<ProductImage>(this.productImages);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  deleteProductImage(id) {
    const c = confirm('Are you sure to delete this image?');
    if (c) {
      this.spinner.show();
      this.productImageService.deleteProductImage(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          Swal.fire('Message', res.message, 'success');
          this.bindProductImages();
        } else {
          Swal.fire('Error', res.message, 'error');
        }
      }, err => {
        this.spinner.hide();
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.router.navigate(['/sign-in']);
          } else {
            
          }
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      });
    }
  }


  showLightbox(index) {
      this.selectedImageIndex = index;
      this.showFlag = true;
  }

  closeEventHandler() {
      this.showFlag = false;
      this.currentIndex = -1;
  }
}
