import { ProductAttribute } from './../../models';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductAttributesService } from './../../product-attributes.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-product-attribute',
  templateUrl: './add-product-attribute.component.html',
  styleUrls: ['./add-product-attribute.component.scss']
})
export class AddProductAttributeComponent implements OnInit, OnDestroy {
  form: FormGroup;
  addProductAttributeSub: Subscription;
  constructor(private productAttributesService: ProductAttributesService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
               }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      attributeName: ['Size', Validators.required],
      attributeValue: ['', Validators.required],
      dimensions: ''
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveProductAttribute() {
    this.spinner.show();

    const productAttribute: ProductAttribute = {
      productID: this.activatedRoute.snapshot.params.id,
      color: this.form.value.color,
      size: this.form.value.size,
      dimensions: this.form.value.dimensions,
      weight: '',
      brand: '',
      physicalStock: 0,
      price: 0,
      salePrice: 0,
      moq: 1
    };

    this.addProductAttributeSub = this.productAttributesService.addAttribute(productAttribute).subscribe(res => {
      alert('Product Attribute added successfully.');
      this.spinner.hide();
      this.router.navigate(['/admin/product-attributes/' + this.activatedRoute.snapshot.params.id]);
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  ngOnDestroy() {
    this.addProductAttributeSub.unsubscribe();
  }

}
