import { SubcategoryService } from 'src/app/subcategory.service';
import { CategoryService } from 'src/app/category.service';
import { MenuService } from 'src/app/menu.service';
import { ProductsService } from './../../products.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SiteSetting, SubCategory, Category, Menu, Product } from 'src/app/models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class AddProductComponent implements OnInit, OnDestroy {

  public Editor = ClassicEditor;
  description: string;
  ckeConfig: any;

  file1: FileUploadModel;
  file2: FileUploadModel;
  file3: FileUploadModel;
  file4: FileUploadModel;

  isInProgress1 = false;
  isInProgress2 = false;
  isInProgress3 = false;
  isInProgress4 = false;
  fileName1 = '';
  fileName2 = '';
  fileName3 = '';
  fileName4 = '';
  uploadResponse1 = { status: '', message: '', filePath: '' };
  uploadResponse2 = { status: '', message: '', filePath: '' };
  uploadResponse3 = { status: '', message: '', filePath: '' };
  uploadResponse4 = { status: '', message: '', filePath: '' };
  error: string;
  form: FormGroup;
  menus: Menu[];
  categories: Category[];
  subcategories: SubCategory[];
  menusSub: Subscription;
  categoriesSub: Subscription = null;
  subcategoriesSub: Subscription = null;
  uploadFile1Sub: Subscription = null;
  uploadFile2Sub: Subscription = null;
  uploadFile3Sub: Subscription = null;
  uploadFile4Sub: Subscription = null;
  saveProductSub: Subscription = null;

  constructor(private productsService: ProductsService,
              private menuService: MenuService,
              private categoryService: CategoryService,
              private subcategoryService: SubcategoryService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: false,
      height: '350px',
      forcePasteAsPlainText: true
    };

    this.bindMenus();
  }

  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      specifications: ['', Validators.required],
      description: ['', Validators.required],
      price: ['', Validators.required],
      menu: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      codPaymentAvailable: ['', Validators.required],
      onlinePaymentAvailable: ['', Validators.required],
      isActive: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onMenuSelected() {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(this.form.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onCategorySelected() {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(this.form.value.category).subscribe(res => {
      this.subcategories = res;
      this.spinner.hide();
    });
  }
  onClickImage1() {
    const fileUpload = document.getElementById('fileUpload1') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file1 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile1(formData);
    };
    fileUpload.click();
  }

  private uploadFile1(formData: FormData) {
    this.uploadFile1Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message < 100) {
          this.uploadResponse1 = res;
          this.isInProgress1 = true;
        } else {
          this.isInProgress1 = false;
        }
        this.fileName1 = this.file1.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  onClickImage2() {
    const fileUpload = document.getElementById('fileUpload2') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file2 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile2(formData);
    };
    fileUpload.click();
  }

  private uploadFile2(formData: FormData) {
    this.uploadFile2Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message < 100) {
          this.uploadResponse2 = res;
          this.isInProgress2 = true;
        } else {
          this.isInProgress2 = false;
        }
        this.fileName2 = this.file2.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  onClickImage3() {
    const fileUpload = document.getElementById('fileUpload3') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file3 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile3(formData);
    };
    fileUpload.click();
  }

  private uploadFile3(formData: FormData) {
    this.uploadFile3Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message < 100) {
          this.uploadResponse3 = res;
          this.isInProgress3 = true;
        } else {
          this.isInProgress3 = false;
        }
        this.fileName3 = this.file3.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  onClickImage4() {
    const fileUpload = document.getElementById('fileUpload4') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file4 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile4(formData);
    };
    fileUpload.click();
  }

  private uploadFile4(formData: FormData) {
    this.uploadFile4Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message < 100) {
          this.uploadResponse4 = res;
          this.isInProgress4 = true;
        } else {
          this.isInProgress4 = false;
        }
        this.fileName4 = this.file4.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  

  public onChangeDescription({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.description = data;
  }

  saveProduct() {
    if (this.fileName1 === '' &&
      this.fileName2 === '' &&
      this.fileName3 === '' &&
      this.fileName4 === '') {
      alert('At least one Product Image must be uploaded.');
      return;
    }

    this.spinner.show();
    const product: Product = new Product();
    product.name = this.form.value.name;
    product.specifications = this.form.value.specifications;
    product.description = this.description;
    product.isActive = this.form.value.isActive;
    product.onlinePaymentAvailable = this.form.value.onlinePaymentAvailable;
    product.codAvailable = this.form.value.codPaymentAvailable;
    product.categoryID = this.form.value.category;
    product.subcategoryID = this.form.value.subcategory;

    this.saveProductSub = this.productsService.addProduct(product).subscribe(res => {
      if (res) {
        alert('Product added successfully.');
        this.router.navigate(['/admin/products']);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.menusSub.unsubscribe();
    if (this.categoriesSub != null) {
      this.categoriesSub.unsubscribe();
    }
    if (this.subcategoriesSub != null) {
      this.subcategoriesSub.unsubscribe();
    }
    if (this.uploadFile1Sub != null) {
      this.uploadFile1Sub.unsubscribe();
    }
    if (this.uploadFile2Sub != null) {
      this.uploadFile2Sub.unsubscribe();
    }
    if (this.uploadFile3Sub != null) {
      this.uploadFile3Sub.unsubscribe();
    }
    if (this.uploadFile4Sub != null) {
      this.uploadFile4Sub.unsubscribe();
    }
    if (this.saveProductSub != null) {
      this.saveProductSub.unsubscribe();
    }
  }

}
