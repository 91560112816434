import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ShippingPincodeSlab } from 'src/app/models';
import { ShippingPincodeSlabService } from 'src/app/shipping-pincode-slab.service';
import { ShippingPincodesService } from 'src/app/shipping-pincodes.service';

@Component({
  selector: 'app-edit-pincode-slab',
  templateUrl: './edit-pincode-slab.component.html',
  styleUrls: ['./edit-pincode-slab.component.scss']
})
export class EditPincodeSlabComponent implements OnInit {

  form: FormGroup;
  shippingPincodeSlab: ShippingPincodeSlab;
  addShippingPincodeSub: Subscription;
  fromPincode = '';
  toPincode = '';
  constructor(private shippingPincodeSlabService: ShippingPincodeSlabService,
              private shippingPincodesService: ShippingPincodesService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindShippingPincodeSlab();
  }

  createForm() {
    this.form = this.fb.group({
      fromWeight: ['', Validators.required],
      toWeight: ['', Validators.required],
      shippingCharges: ['', Validators.required],
      shippingChargesExpress: ['', Validators.required],
      shippingChargesFourWheeler: ['', Validators.required],
      shippingChargesFourWheelerExpress: ['', Validators.required],
      deliveryTime: ['', Validators.required],
      deliveryTimeExpress: ['', Validators.required],
      deliveryTimeFourWheeler: ['', Validators.required],
      deliveryTimeFourWheelerExpress: ['', Validators.required],
    });
  }

  bindShippingPincodeSlab() {
    this.spinner.show();
    this.shippingPincodeSlabService.getSlab(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.spinner.hide();
      this.form.patchValue(res);
      this.shippingPincodeSlab = res;
      this.bindShippingPincode();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  bindShippingPincode() {
    this.spinner.show();
    this.shippingPincodesService.getShippingPincode(this.shippingPincodeSlab.pincodeID)
    .subscribe(res => {
      this.fromPincode = res.fromPincode;
      this.toPincode = res.toPinCode;
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  savePincodeSlab() {
    this.spinner.show();

    const slab = this.form.value;
    slab.id = this.activatedRoute.snapshot.params.id;
    slab.pincodeID = this.shippingPincodeSlab.pincodeID;

    this.addShippingPincodeSub = this.shippingPincodeSlabService.updateSlab(slab).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/admin/pincode-slabs/' + this.shippingPincodeSlab.pincodeID]);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.addShippingPincodeSub.unsubscribe();
  }

}
