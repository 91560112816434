import { BaseApiService } from './../../base-api.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Menu, Category, SubCategory, Product } from 'src/app/models';
import { ProductsService } from 'src/app/products.service';
import { MenuService } from 'src/app/menu.service';
import { CategoryService } from 'src/app/category.service';
import { SubcategoryService } from 'src/app/subcategory.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ChangeEvent, CKEditorComponent } from '@ckeditor/ckeditor5-angular';
import { Subscription } from 'rxjs';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-edit-product',
  templateUrl: './edit-product.component.html',
  styleUrls: ['./edit-product.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EditProductComponent implements OnInit, OnDestroy {

  public Editor = ClassicEditor;
  description: string;
  specifications: string;
  ckeConfig: any;
  product: any;
  baseApiUrl = BaseApiService.baseApiUrl;
  @ViewChild('editor') editorComponent: CKEditorComponent;

  file1: FileUploadModel;
  file2: FileUploadModel;
  file3: FileUploadModel;
  file4: FileUploadModel;

  isInProgress1 = false;
  isInProgress2 = false;
  isInProgress3 = false;
  isInProgress4 = false;
  fileName1 = '';
  fileName2 = '';
  fileName3 = '';
  fileName4 = '';
  uploadResponse1 = { status: '', message: '', filePath: '' };
  uploadResponse2 = { status: '', message: '', filePath: '' };
  uploadResponse3 = { status: '', message: '', filePath: '' };
  uploadResponse4 = { status: '', message: '', filePath: '' };

  uploadFile1Sub: Subscription = null;
  uploadFile2Sub: Subscription = null;
  uploadFile3Sub: Subscription = null;
  uploadFile4Sub: Subscription = null;
  error: string;
  form: FormGroup;
  menus: Menu[];
  categories: Category[];
  subcategories: SubCategory[];
  menusSub: Subscription;
  categoriesSub: Subscription;
  subcategoriesSub: Subscription;
  productSub: Subscription;
  saveProductSub: Subscription = null;

  constructor(private productsService: ProductsService,
    private menuService: MenuService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: false,
      height: '350px',
      forcePasteAsPlainText: true
    };

    this.bindProduct();
  }

  bindProduct() {
    this.spinner.show();
    this.productSub = this.productsService.getById(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.product = res;
      this.form.patchValue({
        name: this.product.name,
        itemDescription: this.product.itemDescription,
        shortDescription: this.product.shortDescription,
        specifications: this.product.specifications,
        desctiption: res.description,
        isActive: this.product.isActive,
        onlinePaymentAvailable: this.product.onlinePaymentAvailable,
        codPaymentAvailable: this.product.codAvailable,
        keywords: this.product.keywords,
        commissionPercentage: this.product.commissionPercentage
      });
      this.specifications = res.specifications;
      this.description = res.description;
      this.editorComponent.editorInstance.setData(this.description);
      this.spinner.hide();
      this.bindMenus();
    });
  }

  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
      this.bindCategories(this.product.menuID);
    });
  }

  bindCategories(menuId: number) {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(menuId).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
      this.bindSubCategories(this.product.categoryID);
    });
  }

  bindSubCategories(categoryId: number) {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(categoryId).subscribe(res => {
      this.subcategories = res;
      this.form.patchValue({
        menu: this.product.menuID,
        category: this.product.categoryID,
        subcategory: this.product.subCategoryID
      });
      this.fileName1 = '';
      this.fileName2 = '';
      this.fileName3 = '';
      this.fileName4 = '';
      console.log(this.fileName1);
      console.log(this.product.imagePath1);
      this.fileName1 = this.product.imagePath1;
      this.fileName2 = this.product.imagePath2;
      this.fileName3 = this.product.imagePath3;
      this.fileName4 = this.product.imagePath4;

      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      itemDescription: [''],
      shortDescription: ['', Validators.required],
      specifications: ['', Validators.required],
      description: ['', Validators.required],
      isActive:[''],
      menu: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      codPaymentAvailable: [''],
      onlinePaymentAvailable: [''],
      keywords: ['', Validators.required],
      commissionPercentage: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onMenuSelected() {
    this.spinner.show();
    this.categoryService.getByMenu(this.form.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onCategorySelected() {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(this.form.value.category).subscribe(res => {
      this.subcategories = res;
      this.spinner.hide();
    });
  }
  onClickImage1() {
    const fileUpload = document.getElementById('fileUpload1') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file1 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile1(formData);
    };
    fileUpload.click();
  }

  private uploadFile1(formData: FormData) {
    this.uploadFile1Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse1 = res;
          this.isInProgress1 = true;
        }
        this.fileName1 = 'Seller/Product/' + this.activatedRoute.snapshot.params.username + '/' + this.file1.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  onClickImage2() {
    const fileUpload = document.getElementById('fileUpload2') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file2 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile2(formData);
    };
    fileUpload.click();
  }

  private uploadFile2(formData: FormData) {
    this.uploadFile2Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse2 = res;
          this.isInProgress2 = true;
        }
        this.fileName2 = 'Seller/Product/' + this.activatedRoute.snapshot.params.username + '/' + this.file2.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  onClickImage3() {
    const fileUpload = document.getElementById('fileUpload3') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file3 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile3(formData);
    };
    fileUpload.click();
  }

  private uploadFile3(formData: FormData) {
    this.uploadFile3Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse3 = res;
          this.isInProgress3 = true;
        }
        this.fileName3 = 'Seller/Product/' + this.activatedRoute.snapshot.params.username + '/' + this.file3.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }
  onClickImage4() {
    const fileUpload = document.getElementById('fileUpload4') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file4 = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile4(formData);
    };
    fileUpload.click();
  }

  private uploadFile4(formData: FormData) {
    this.uploadFile4Sub = this.productsService.uploadProductImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse4 = res;
          this.isInProgress4 = true;
        }
        this.fileName4 = 'Seller/Product/' + this.activatedRoute.snapshot.params.username + '/' + this.file4.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }


  public onChangeDescription({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.description = data;
  }

  public onChangeSpecifications({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.specifications = data;
  }

  saveProduct() {

    this.spinner.show();
    const product: Product = new Product();
    product.id = this.product.id;
    product.addedOn = this.product.addedOn;
    product.name = this.form.value.name;
    product.itemDescription = this.form.value.itemDescription;
    product.shortDescription = this.form.value.shortDescription;
    product.specifications = this.specifications;
    product.description = this.description;
    product.isActive = this.form.value.isActive;
    product.onlinePaymentAvailable = this.form.value.onlinePaymentAvailable;
    product.codAvailable = this.form.value.codPaymentAvailable;
    product.categoryID = this.form.value.category;
    product.subcategoryID = this.form.value.subcategory;
    product.username = this.product.user.username;
    product.keywords = this.form.value.keywords;
    product.commissionPercentage = this.form.value.commissionPercentage;
    product.modifiedBy = 'admin';

    this.saveProductSub = this.productsService.updateProduct(product).subscribe(res => {
      if (res.added) {
        alert(res.message);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.uploadFile1Sub != null) {
      this.uploadFile1Sub.unsubscribe();
    }
    if (this.uploadFile2Sub != null) {
      this.uploadFile2Sub.unsubscribe();
    }
    if (this.uploadFile3Sub != null) {
      this.uploadFile3Sub.unsubscribe();
    }
    if (this.uploadFile4Sub != null) {
      this.uploadFile4Sub.unsubscribe();
    }
    if (this.saveProductSub != null) {
      this.saveProductSub.unsubscribe();
    }
    this.menusSub.unsubscribe();
    this.categoriesSub.unsubscribe();
    this.subcategoriesSub.unsubscribe();
    this.productSub.unsubscribe();
  }

}
