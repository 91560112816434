import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SlidersService } from 'src/app/sliders.service';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Slider, Banner } from 'src/app/models';
import { BannersService } from 'src/app/banners.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-add-banner',
  templateUrl: './add-banner.component.html',
  styleUrls: ['./add-banner.component.scss']
})
export class AddBannerComponent implements OnInit {

  file: FileUploadModel;
  bannerImageUploaded = false;
  isInProgress = false;
  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;
  form: FormGroup;
  uploadFileSub: Subscription;
  addBannerSub: Subscription = null;

  imgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  constructor(private bannersService: BannersService, private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      bannerNo: ['', Validators.required],
      routerLink: ['', Validators.required],
      bigText: ['', Validators.required],
      smallText: ['', Validators.required],
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.imgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];  
        
      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.imgValidations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG") ) {
        this.imgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 300) {
        this.imgValidations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.imgValidations.validDimension =  ( width !=  900 || height != 600 ) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.imgValidations.validDimension || 
              !this.imgValidations.validSize ||
              !this.imgValidations.validType) {

                let errorMsg = "";
                if (!this.imgValidations.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.imgValidations.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.imgValidations.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile(formData);
          }
        };
      };


    };
    console.log('clicking fileupload....');
    fileUpload.click();
  }



  private uploadFile(formData: FormData) {
    this.uploadFileSub = this.bannersService.uploadBannerImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        }
        this.fileName = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  saveBanner() {
    if (this.fileName === '') {
      alert('Please upload the file first.');
      return;
    }
    this.spinner.show();
    const banner: Banner = {
      imagePath: 'Banners/' + this.fileName,
      routerLink: this.form.value.routerLink,
      bannerNo: this.form.value.bannerNo,
      bigText: this.form.value.bigText,
      smallText: this.form.value.smallText
    };
    this.addBannerSub = this.bannersService.addBanner(banner).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/admin/banners']);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          this.router.navigate(['/sign-in']);
        } else {
          alert('Error occured. Please try again...');
        }
      } else {
        alert('Error occured. Please try again...');
      }
      
    });
  }

  ngOnDestroy() {
    if (this.uploadFileSub != null) {
      this.uploadFileSub.unsubscribe();
    }
    if (this.addBannerSub != null) {
      this.addBannerSub.unsubscribe();
    }
  }
}


