import { HomePageHeading } from './../../models';
import { HomepageHeadingService } from './../../homepage-heading.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Menu } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-homepage-heading',
  templateUrl: './add-homepage-heading.component.html',
  styleUrls: ['./add-homepage-heading.component.scss']
})
export class AddHomepageHeadingComponent implements OnInit, OnDestroy {

  form: FormGroup;
  addHomePageHeadingSub: Subscription;
  constructor(private homepageHeadingService: HomepageHeadingService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      headingName: ['', Validators.required],
      headingOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveHeading() {
    this.spinner.show();
    const heading: HomePageHeading = {
      headingName: this.form.value.headingName,
      headingOrder: this.form.value.headingOrder
    };
    this.addHomePageHeadingSub = this.homepageHeadingService.addHomePageHeading(heading).subscribe(added => {
      if (added) {
        alert('Homepage Heading saved successfully.');
        this.router.navigate(['/admin/homepage-headings']);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.addHomePageHeadingSub != null) {
      this.addHomePageHeadingSub.unsubscribe();
    }
  }

}
