import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { Banner, Slider } from 'src/app/models';
import { Subscription } from 'rxjs';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { SlidersService } from 'src/app/sliders.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BannersService } from 'src/app/banners.service';

@Component({
  selector: 'app-banners',
  templateUrl: './banners.component.html',
  styleUrls: ['./banners.component.scss']
})
export class BannersComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  banners: Banner[] = [];
  bannersSub: Subscription;
  deleteBannersSub: Subscription;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Banner>(this.banners);
  displayedColumns: string[] = ['imagePath', 'bannerNo', 'bigText', 'smallText', 'actionEdit', 'actionDelete'];
  constructor(private bannersService: BannersService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindBanners();
  }

  bindBanners() {
    this.spinner.show();
    this.bannersSub = this.bannersService.getAll().subscribe(res => {
      this.banners = res;
      this.dataSource = new MatTableDataSource<Banner>(this.banners);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteBanner(id: number) {
    const deleted = confirm('Are you sure you want to delete this banner?');
    if (deleted) {
      this.spinner.show();
      this.deleteBannersSub = this.bannersService.deleteBanner(id).subscribe(res => {
        if (res.added) {
          alert(res.message);
          this.bindBanners();
        } else {
          alert(res.message);
        }
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }

  }

  ngOnDestroy() {
    if (this.deleteBannersSub != null) {
      this.deleteBannersSub.unsubscribe();
    }
    this.bannersSub.unsubscribe();
  }

}
