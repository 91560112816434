import { ActivatedRoute } from '@angular/router';
import { Contact } from './../../models';
import { NgxSpinnerService } from 'ngx-spinner';
import { ContactService } from './../../contact.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss']
})
export class ContactDetailsComponent implements OnInit, OnDestroy {
  contact: Contact;
  contactSub: Subscription;

  constructor(private contactService: ContactService, private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.spinner.show();
    this.contactSub = this.contactService.getContact(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.contact = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please reload this page and try again...');
    });
  }

  ngOnDestroy() {
    this.contactSub.unsubscribe();
  }

}
