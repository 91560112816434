import { BaseApiService } from './base-api.service';
import { HttpClient, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OrderMasterObject, Order, OrderDetails, OrderHistory, PaymentOrder, RequestOrderDetails, RequestOrder, RequestOrderHistory, CustomOrder } from './models';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(private http: HttpClient) { }

  // save order of user
  addOrder(obj: OrderMasterObject): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/Post';

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, obj, { headers });
  }

  // check if order can be placed
  checkOrder(obj: OrderMasterObject): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/CheckOrder';

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, obj, { headers });
  }

  addPaymentOrder(obj: PaymentOrder): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/InsertPaymentOrder';

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, obj, { headers });
  }

  updatePaymentOrder(obj: PaymentOrder): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/UpdatePaymentOrder';

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, obj, { headers });
  }

  getShippingCharges(obj: OrderMasterObject, toPincode: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetShippingCharges';

    return this.http.post<any>(url, { toPincode: toPincode, obj: obj });
  }

  createRazorPaymentOrder(orderId: number, currency: string, amount: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/CreateRazorPaymentOrder?orderId=' + orderId +
      '&currency=' + currency + '&amount=' + amount;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  verifyPayment(orderId: string, paymentId: string, signature: string): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/Order/VerifyPayment?razorPayOrderId=' + orderId +
      '&razorPayPaymentId=' + paymentId + '&signature=' + signature;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.get<boolean>(url, { headers });
  }

  addOrderComments(orderId: number, dateModified: string, comments: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/AddComments';

    const data = {
      orderId,
      dateModified,
      comments
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, data, { headers });
  }

  addCustomOrderComments(orderId: number, dateModified: string, comments: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/AddCustomOrderComments';

    const data = {
      orderId,
      dateModified,
      comments
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, data, { headers });
  }

  addRequestOrderComments(orderId: number, dateModified: string, comments: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/AddRequestOrderComments';

    const data = {
      orderId,
      dateModified,
      comments
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, data, { headers });
  }

  updateOrderStatus(orderId: number, status: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/UpdateStatus';

    const data = {
      orderId,
      status
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.put<any>(url, data, { headers });
  }

  updateCustomOrder(order: CustomOrder): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/UpdateCustomOrder?isWeb=' + true;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, order, { headers });
  }

  updateCustomOrderStatus(orderId: number, status: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/UpdateCustomOrderStatus';

    const data = {
      orderId,
      status
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.put<any>(url, data, { headers });
  }

  updateRequestOrderStatus(orderId: number, status: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/UpdateRequestOrderStatus';

    const data = {
      orderId,
      status
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.put<any>(url, data, { headers });
  }

  updateCustomOrderShippingCharges(orderId: number, shippingCharges: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/UpdateShippingChargesCustomOrder?orderId=' +
      orderId + '&shippingCharges=' + shippingCharges;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.put<any>(url, null, { headers });
  }

  updateRequestOrderShippingCharges(orderId: number, shippingCharges: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/UpdateShippingChargesRequestOrder?orderId=' +
      orderId + '&shippingCharges=' + shippingCharges;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.put<any>(url, null, { headers });
  }

  updateCustomOrderPaymentDetails(orderId: number, paymentMethod: string, transportationMode: string,
    deliveryMode: string) {
      const url = BaseApiService.baseApiUrl + 'api/Order/UpdatePaymentDetails?orderId=' +
      orderId + '&paymentMethod=' + paymentMethod + '&transportationMode=' +
      transportationMode + '&deliveryMode=' + deliveryMode;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.put<any>(url, null, { headers });
  }

  updateRequestOrderPaymentDetails(orderId: number, paymentMethod: string,
    deliveryMode: string) {
      const url = BaseApiService.baseApiUrl + 'api/Order/UpdatePaymentDetailsRequestOrder?orderId=' +
      orderId + '&paymentMethod=' + paymentMethod +
      '&deliveryMode=' + deliveryMode;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.put<any>(url, null, { headers });
  }

  finalizeRequestOrder(orderId: number, discount: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/FinalizeRequestOrder?orderId=' +
      orderId + '&discount=' + discount;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  getOrdersOverViewForUser(mobile: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrdersOverviewForUser?mobile=' + mobile;

    return this.http.get<any>(url);
  }

  getOrders(startDate: string, endDate: string, status: string, pageNumber: number,
    pageSize: number, orderId: number, customerName: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrders?startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize + '&orderId=' + orderId + '&customerName=' + customerName;

    return this.http.get<any>(url);
  }

  getCustomOrders(startDate: string, endDate: string, status: string, pageNumber: number,
    pageSize: number, orderId: number, customerName: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetCustomOrders?startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize + '&orderId=' + orderId + '&customerName=' + customerName;

    return this.http.get<any>(url);
  }

  getRequestOrders(startDate: string, endDate: string, status: string, pageNumber: number,
    pageSize: number, orderId: number, customerName: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetRequestOrders?startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize + '&orderId=' + orderId + '&customerName=' + customerName;

    return this.http.get<any>(url);
  }

  getOrdersForSeller(startDate: string, endDate: string, status: string, pageNumber: number,
    pageSize: number): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrdersForSeller?username=' + username +
      '&startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize;

    return this.http.get<any>(url);
  }

  getOrdersForLogisticPartner(startDate: string, endDate: string, status: string, pageNumber: number,
    pageSize: number): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrdersForLogisticPartner?username=' + username +
      '&startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize;

    return this.http.get<any>(url);
  }

  getOrdersCountForLogisticPartner(): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrdersCountForLogisticPartner?username=' + username;

    return this.http.get<any>(url);
  }

  getSalesForSeller(): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/Order/GetSalesForSeller?username=' + username;

    return this.http.get<any>(url);
  }

  downloadOrders(startDate: string, endDate: string, status: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadOrders?startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadCustomOrders(startDate: string, endDate: string, status: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadCustomOrders?startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadRequestOrders(startDate: string, endDate: string, status: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadRequestOrders?startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadOrdersForSeller(startDate: string, endDate: string, status: string): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'Download/DownloadOrdersForSeller?username=' + username +
      '&startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadOrdersForLogisticPartner(startDate: string, endDate: string, status: string): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'Download/DownloadOrdersForLogisticPartner?username=' + username +
      '&startDate=' + startDate +
      '&endDate=' + endDate + '&status=' + status;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadOrderBill(orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadOrderBill?orderId=' + orderId;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }
  downloadCustomOrderBill(orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadCustomOrderBill?orderId=' + orderId;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  downloadRequestOrderBill(orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'Download/DownloadRequestOrderBill?orderId=' + orderId;

    return this.http.request(new HttpRequest('GET', url, null,
      {
        reportProgress: true,
        responseType: 'blob'
      }));
  }

  assignOrderToLogisticPartner(username: string, orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/AssignOrderToLogisticsPartner?username=' +
      username + '&orderId=' + orderId;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }
  assignCustomOrderToLogisticPartner(username: string, orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/AssignCustomOrderToLogisticsPartner?username=' +
      username + '&orderId=' + orderId;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  assignRequestOrderToLogisticPartner(username: string, orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/AssignRequestOrderToLogisticsPartner?username=' +
      username + '&orderId=' + orderId;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  getOrdersForUser(pageNumber: number, status: string): Observable<any> {
    const username = JSON.parse(localStorage.getItem('token')).username;
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrdersForUser?username=' +
      username + '&pageNumber=' + pageNumber + '&status=' + status;

    return this.http.get<any>(url);
  }

  getOrder(id: number): Observable<Order> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrder/' + id;

    return this.http.get<Order>(url);
  }
  getCustomOrder(id: number): Observable<Order> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetCustomOrder/' + id;

    return this.http.get<Order>(url);
  }

  getRequestOrder(id: number): Observable<RequestOrder> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetRequestOrder/' + id;

    return this.http.get<RequestOrder>(url);
  }

  getOrderDetails(id: number): Observable<OrderDetails[]> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrderDetails?orderId=' + id;

    return this.http.get<OrderDetails[]>(url);
  }
  getCustomOrderDetails(id: number): Observable<OrderDetails[]> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetCustomOrderDetails?orderId=' + id;

    return this.http.get<OrderDetails[]>(url);
  }

  getRequestOrderDetails(id: number): Observable<RequestOrderDetails[]> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetRequestOrderDetails?orderId=' + id;

    return this.http.get<RequestOrderDetails[]>(url);
  }

  getOrderHistories(id: number): Observable<OrderHistory[]> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetOrderHistory?orderId=' + id;

    return this.http.get<OrderHistory[]>(url);
  }
  getCustomOrderHistories(id: number): Observable<OrderHistory[]> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetCustomOrderHistory?orderId=' + id;

    return this.http.get<OrderHistory[]>(url);
  }
  getRequestOrderHistories(id: number): Observable<RequestOrderHistory[]> {
    const url = BaseApiService.baseApiUrl + 'api/Order/GetRequestOrderHistory?orderId=' + id;

    return this.http.get<RequestOrderHistory[]>(url);
  }

  initiateReturn(orderId: number, reason: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/InitiateReturn?orderId=' + orderId +
      '&reason=' + reason;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  cancelOrder(orderId: number, reason: string, isAdmin: boolean): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/CancelOrder?orderId=' + orderId +
      '&reason=' + reason + '&isAdmin=' + isAdmin;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }
  cancelCustomOrder(orderId: number, reason: string, isAdmin: boolean): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/CancelCustomOrder?orderId=' + orderId +
      '&reason=' + reason + '&isAdmin=' + isAdmin;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  cancelRequestOrder(orderId: number, reason: string, isAdmin: boolean): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/CancelRequestOrder?orderId=' + orderId +
      '&reason=' + reason + '&isAdmin=' + isAdmin;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  returnOrder(orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/ReturnOrder?orderId=' + orderId;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }
  returnCustomOrder(orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/ReturnCustomOrder?orderId=' + orderId;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  returnRequestOrder(orderId: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/ReturnRequestOrder?orderId=' + orderId;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }

  //
  
  // confirm request order
  confirmRequestOrder(orderId: number, sellerDispatchCode: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/ConfirmRequestOrder';

    const data = {
      orderId,
      sellerDispatchCode
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, data, { headers });
  }
  confirmOrder(orderId: number, sellerDispatchCode: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/ConfirmOrder';

    const data = {
      orderId,
      sellerDispatchCode
    };

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, data, { headers });
  }

  removeProductFromOrder(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/RemoveProductFromOrder/' + id;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.delete<any>(url, { headers });
  }
  removeProductFromCustomOrder(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/RemoveProductFromCustomOrder/' + id;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.delete<any>(url, { headers });
  }

  removeProductFromRequestOrder(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/RemoveProductFromRequestOrder/' + id;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.delete<any>(url, { headers });
  }

  deliverOrder(orderId: number, customerDeliveryCode: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/Order/DeliverOrder?orderId=' + orderId +
      '&deliveryCode=' + customerDeliveryCode;

    const token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });

    return this.http.post<any>(url, null, { headers });
  }
}
