import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ShippingPincode } from './models';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ShippingPincodesService {

  constructor(private http: HttpClient) { }

  public getShippingPincodes(fromPincode: string, toPincode: string,
    state: string, district: string, pageNumber: number, pageSize: number, sort: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincode/GetShippingPincodes?fromPincode=' +
    fromPincode + '&toPincode=' + toPincode + '&state=' + state +
    '&district=' + district + '&pageNumber=' + pageNumber + 
    '&pageSize=' + pageSize + '&sort=' + sort;

    return this.http.get<any>(url);
  }

  public getShippingPincode(id: number): Observable<ShippingPincode> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincode/GetShippingPincode/' + id;

    return this.http.get<ShippingPincode>(url);
  }

  public addShippingPincode(shippingPincode: any): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincode/AddShippingPincode';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, shippingPincode, {headers});
  }

  public updateShippingPincode(shippingPincode: any): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincode/UpdateShippingPincode';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, shippingPincode, {headers});
  }

  public deleteShippingPincode(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincode/DeletePincode/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }
}
