import { ContactComponent } from './contact/contact.component';
import { AdminAuthGuard } from './admin-auth.guard';
import { SignInComponent } from './sign-in/sign-in.component';
import { HomeComponent } from './home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminModule } from './admin/admin.module';
import { AboutUsComponent } from './about-us/aboutus.component';
import { CustomerTcComponent } from './customter-tc/customer-tc.component';
import { OurPricingComponent } from './our-pricing/our-pricing.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ReturnRefundPolicyComponent } from './return-refund-policy/return-refund-policy.component';
import { SellerTcComponent } from './seller-tc/seller-tc.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';


const routes: Routes = [
  {path: 'admin', canActivate: [AdminAuthGuard], loadChildren: () => AdminModule},
  {path: '', component: HomeComponent,
   children: [
     {path: '', component: SignInComponent},
     {path: 'sign-in', component: SignInComponent},
     {path: 'contact', component: ContactComponent},
     {path: 'aboutus', component: AboutUsComponent},
     {path: 'customer-tc', component: CustomerTcComponent},
     {path: 'our-pricing', component: OurPricingComponent},
     {path: 'privacy-policy', component: PrivacyPolicyComponent},
     {path: 'return-refund-policy', component: ReturnRefundPolicyComponent},
     {path: 'seller-tc', component: SellerTcComponent},
     {path: 'forgot-password', component: ForgotPasswordComponent},
     {path: 'resetPassword', component: ResetPasswordComponent},
   ]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
