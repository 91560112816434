import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ExpensesService {
  constructor(private http: HttpClient) { }

  // adds the expense to the database
  addExpense(expense: any): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/expense/AddExpense';


    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.post<any>(url, expense, { headers });
  }

  updateExpense(expense: any): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/expense/UpdateExpense';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.put<any>(url, expense, { headers });
  }

  getExpensesByDateRange(fromDate: string, toDate: string,
    pageNumber: number, pageSize: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/expense/GetExpensesByDateRange?fromDate=' +
      fromDate + '&toDate=' + toDate + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.get<any>(url, { headers });
  }

  getIncome(fromDate: string, toDate: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/expense/GetIncome?fromDate=' +
      fromDate + '&toDate=' + toDate;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.get<any>(url, { headers });
  }

  getExpensesByUsernameAndDateRange(fromDate: string, toDate: string,
    pageNumber: number, pageSize: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/expense/GetExpensesByUsernameAndDateRange?fromDate=' +
      fromDate + '&toDate=' + toDate + '&pageNumber=' + pageNumber +
      '&pageSize=' + pageSize;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.get<any>(url, { headers });
  }

  getExpensesByLogisticPartner(username: string, fromDate: string, toDate: string): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/expense/GetExpensesByLogisticPartner?username=' + username + 
    '&fromDate=' +
      fromDate + '&toDate=' + toDate;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.get<any>(url, { headers });
  }

  getExpense(id: any): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/expense/GetExpense?id=' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token });

    return this.http.get<any>(url, { headers });
  }

  uploadExpenseImage(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/Expense/UploadExpenseImage';

    let token = JSON.parse(localStorage.getItem('token'));

    const headers = new HttpHeaders({ Authorization: 'Bearer ' + token.access_token });
   
    formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      headers: headers,
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }
}
