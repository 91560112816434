import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactsComponent } from './contacts/contacts.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrdersComponent } from './orders/orders.component';
import { EditProductAttributeComponent } from './edit-product-attribute/edit-product-attribute.component';
import { AddProductAttributeComponent } from './add-product-attribute/add-product-attribute.component';
import { ProductAttributesComponent } from './product-attributes/product-attributes.component';
import { EditStockComponent } from './edit-stock/edit-stock.component';
import { StocksComponent } from './stocks/stocks.component';
import { AddStockComponent } from './add-stock/add-stock.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AddHomepageProductComponent } from './add-homepage-product/add-homepage-product.component';
import { EditHomepageHeadingComponent } from './edit-homepage-heading/edit-homepage-heading.component';
import { HomepageProductsComponent } from './homepage-products/homepage-products.component';
import { AddHomepageHeadingComponent } from './add-homepage-heading/add-homepage-heading.component';
import { HomepageHeadingsComponent } from './homepage-headings/homepage-headings.component';
import { EditSubcategoryComponent } from './edit-subcategory/edit-subcategory.component';
import { AddSubcategoryComponent } from './add-subcategory/add-subcategory.component';
import { EditCategoryComponent } from './edit-category/edit-category.component';
import { AddCategoryComponent } from './add-category/add-category.component';
import { SubcategoriesComponent } from './subcategories/subcategories.component';
import { CategoriesComponent } from './categories/categories.component';
import { AddMenuComponent } from './add-menu/add-menu.component';
import { EditMenuComponent } from './edit-menu/edit-menu.component';
import { MenusComponent } from './menus/menus.component';
import { SiteSettingsComponent } from './site-settings/site-settings.component';
import { EditSliderComponent } from './edit-slider/edit-slider.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { SlidersComponent } from './sliders/sliders.component';
import { ProductsComponent } from './products/products.component';
import { AddSliderComponent } from './add-slider/add-slider.component';
import { PincodesComponent } from './pincodes/pincodes.component';
import { EditPincodeComponent } from './edit-pincode/edit-pincode.component';
import { AddPincodeComponent } from './add-pincode/add-pincode.component';
import { SellersComponent } from './sellers/sellers.component';
import { SellerDetailsComponent } from './seller-details/seller-details.component';
import { NotificationService } from '../notification.service';
import { NotificationsComponent } from './notifications/notifications.component';
import { AdminProductImagesComponent } from './admin-product-images/admin-product-images.component';
import { LogisticPartnersComponent } from './logistic-partners/logistic-partners.component';
import { LogisticPartnerDetailsComponent } from './logistic-partner-details/logistic-partner-details.component';
import { BannersComponent } from './banners/banners.component';
import { AddBannerComponent } from './add-banner/add-banner.component';
import { EditBannerComponent } from './edit-banner/edit-banner.component';
import { ProductInfoComponent } from './product-info/product-info.component';
import { TopCategoriesComponent } from './top-categories/top-categories.component';
import { AddTopCategoryComponent } from './add-topcategory/add-topcategory.component';
import { EditTopCategoryComponent } from './edit-topcategory/edit-topcategory.component';
import { SellerRegisterComponent } from './seller-register/seller-register.component';
import { ColorsComponent } from './colors/colors.component';
import { AddColorComponent } from './add-color/add-color.component';
import { EditColorComponent } from './edit-color/edit-color.component';
import { SellerReceiptComponent } from './seller-receipt/seller-receipt.component';
import { PincodeSlabsComponent } from './pincode-slabs/pincode-slabs.component';
import { AddPincodeSlabComponent } from './add-pincode-slab/add-pincode-slab.component';
import { EditPincodeSlabComponent } from './edit-pincode-slab/edit-pincode-slab.component';
import { EditHomepageProductComponent } from './edit-homepage-product/edit-homepage-product.component';
import { CustomOrdersComponent } from './custom-orders/custom-orders.component';
import { CustomOrderDetailsComponent } from './custom-order-details/custom-order-details.component';
import { RequestOrdersComponent } from './request-orders/request-orders.component';
import { RequestOrderDetailsComponent } from './request-order-details/request-order-details.component';
import { SellerPaymentsComponent } from './seller-payments/seller-payments.component';
import { ExpensesComponent } from './expenses/expenses.component';
import { AddExpenseComponent } from './add-expense/add-expense.component';


const routes: Routes = [
  {path: '', component: AdminDashboardComponent,
children: [
  {path: 'sliders', component: SlidersComponent},
  {path: 'topcategories', component: TopCategoriesComponent},
  {path: 'add-topcategory', component: AddTopCategoryComponent},
  {path: 'edit-topcategory/:id', component: EditTopCategoryComponent},
  {path: 'menus', component: MenusComponent},
  {path: 'logistic-partners', component: LogisticPartnersComponent},
  {path: 'logistic-partner-details/:username', component: LogisticPartnerDetailsComponent},
  {path: 'categories/:id', component: CategoriesComponent},
  {path: 'subcategories/:id', component: SubcategoriesComponent},
  {path: 'products/:username', component: ProductsComponent},
  {path: 'seller-details/:username', component: SellerDetailsComponent},
  {path: 'add-seller', component: SellerRegisterComponent},
  {path: 'add-slider', component: AddSliderComponent},
  {path: 'edit-slider/:id', component: EditSliderComponent},
  {path: 'add-menu', component: AddMenuComponent},
  {path: 'edit-menu/:id', component: EditMenuComponent},
  {path: 'add-category/:id', component: AddCategoryComponent},
  {path: 'edit-category/:id', component: EditCategoryComponent},
  {path: 'add-subcategory/:id', component: AddSubcategoryComponent},
  {path: 'edit-subcategory/:id', component: EditSubcategoryComponent},
  {path: 'homepage-headings', component: HomepageHeadingsComponent},
  {path: 'add-homepage-heading', component: AddHomepageHeadingComponent},
  {path: 'edit-homepage-heading/:id', component: EditHomepageHeadingComponent},
  {path: 'homepage-products/:id', component: HomepageProductsComponent},
  {path: 'add-homepage-product/:id', component: AddHomepageProductComponent},
  {path: 'add-product', component: AddProductComponent},
  {path: 'edit-product/:id', component: EditProductComponent},
  {path: 'product-info/:id', component: ProductInfoComponent},
  {path: 'site-settings', component: SiteSettingsComponent},
  {path: 'stocks/:id', component: StocksComponent},
  {path: 'add-stock/:id', component: AddStockComponent},
  {path: 'edit-stock/:id', component: EditStockComponent},
  {path: 'product-attributes/:id', component: ProductAttributesComponent},
  {path: 'add-product-attribute/:id', component: AddProductAttributeComponent},
  {path: 'edit-product-attribute/:id', component: EditProductAttributeComponent},
  {path: 'orders', component: OrdersComponent},
  {path: 'order-details/:id', component: OrderDetailsComponent},
  {path: 'contacts', component: ContactsComponent},
  {path: 'contact-details/:id', component: ContactDetailsComponent},
  {path: 'pincodes', component: PincodesComponent},
  {path: 'add-pincode', component: AddPincodeComponent},
  {path: 'edit-pincode/:id', component: EditPincodeComponent},
  {path: 'sellers', component: SellersComponent},
  {path: 'notifications', component: NotificationsComponent},
  {path: 'admin-product-images/:id', component: AdminProductImagesComponent},
  {path: 'banners', component: BannersComponent},
  {path: 'add-banner', component: AddBannerComponent},
  {path: 'edit-banner/:id', component: EditBannerComponent},
  {path: 'colors', component: ColorsComponent},
  {path: 'add-color', component: AddColorComponent},
  {path: 'edit-color/:id', component: EditColorComponent},
  {path: 'seller-receipt/:name', component: SellerReceiptComponent},
  {path: 'pincode-slabs/:id', component: PincodeSlabsComponent},
  {path: 'add-pincode-slab/:id', component: AddPincodeSlabComponent},
  {path: 'edit-pincode-slab/:id', component: EditPincodeSlabComponent},
  {path: 'edit-homepage-product/:id', component: EditHomepageProductComponent},
  {path: 'custom-orders', component: CustomOrdersComponent},
  {path: 'custom-order-details/:id', component: CustomOrderDetailsComponent},
  {path: 'request-orders', component: RequestOrdersComponent},
  {path: 'request-order-details/:id', component: RequestOrderDetailsComponent},
  {path: 'seller-payments', component: SellerPaymentsComponent},
  {path: 'add-expense', component: AddExpenseComponent},
  {path: 'expenses', component: ExpensesComponent},
]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminRoutingModule { }
