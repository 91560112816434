import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { trigger, state, style, transition, animate } from '@angular/animations';
import { SlidersService } from 'src/app/sliders.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Slider } from 'src/app/models';
import { Subscription } from 'rxjs';
import { BaseApiService } from 'src/app/base-api.service';
import Swal from 'sweetalert2';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-edit-slider',
  templateUrl: './edit-slider.component.html',
  styleUrls: ['./edit-slider.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EditSliderComponent implements OnInit, OnDestroy {
  baseApiUrl = BaseApiService.baseApiUrl;
  previvewImgURL: any;
  previvewImgMobileURL: any;
  previvewImgTabURL: any;
  text = 'Select Slider Image ';
  file: FileUploadModel;
  sliderImageUploaded = false;
  isInProgress = false;
  isInProgress1 = false;
  isInProgress2 = false;
  fileName = '';
  fileNameMobile = '';
  fileNameTab = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  uploadResponse1 = { status: '', message: '', filePath: '' };
  uploadResponse2 = { status: '', message: '', filePath: '' };
  error: string;
  sliderForm: FormGroup;
  slider: Slider;
  sliderSub: Subscription;
  uploadFileSub: Subscription = null;
  saveSliderSub: Subscription = null;
  imgValidations = {
    uploadAttempted: false,
    validDimension: false,
    validType: false,
    validSize: false
  };
  imgValidationsMobile = {
    uploadAttempted: false,
    validDimension: false,
    validType: false,
    validSize: false
  };
  imgValidationsTab = {
    uploadAttempted: false,
    validDimension: false,
    validType: false,
    validSize: false
  };

  constructor(private slidersService: SlidersService, private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute) {
                this.createForm();
              }

  ngOnInit(): void {
    this.spinner.show();
    this.sliderSub = this.slidersService.getSlider(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.slider = res;
      this.sliderForm.patchValue({
        displayOrder: this.slider.displayOrder,
        bigLabel: this.slider.bigLabel,
        smallLabel: this.slider.smallLabel
      });
      this.fileName = res.imagePath == null ? '' : this.slider.imagePath.substring(res.imagePath.lastIndexOf('/') + 1);
      this.fileNameMobile = res.mobileImagePath == null ? '' : this.slider.mobileImagePath.substring(res.mobileImagePath.lastIndexOf('/') + 1);
      this.spinner.hide();
    });
  }

  createForm() {
    this.sliderForm = this.fb.group({
      displayOrder: ['', Validators.required],
      bigLabel: ['', Validators.required],
      smallLabel: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.sliderForm.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  
  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.imgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];  
        
      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.imgValidations.validType = false;
       } else {
         this.imgValidations.validType = true;
       }

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG" ||
        mimeType == "image/gif")) {
        this.imgValidations.validType = false;
      } else {
        this.imgValidations.validType = true;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 700) {
        this.imgValidations.validSize = false;
      } else {
        this.imgValidations.validSize = true;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.imgValidations.validDimension =  ( width !=  1440|| height != 300 ) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.imgValidations.validDimension || 
              !this.imgValidations.validSize ||
              !this.imgValidations.validType) {

                let errorMsg = "";
                if (!this.imgValidations.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.imgValidations.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.imgValidations.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            this.previvewImgURL = reader.result;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile(formData);
          }
        };
      };


    };
    console.log('clicking fileupload....');
    fileUpload.click();
  }


  private uploadFile(formData: FormData) {
    this.uploadFileSub = this.slidersService.uploadSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message < 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        } else {
          this.isInProgress = false;
        }
        this.fileName = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  onClickMobile() {
    const fileUpload = document.getElementById('fileUpload1') as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.imgValidationsMobile.uploadAttempted = true;
      const file = fileUpload.files[0];  
        
      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.imgValidationsMobile.validType = false;
       } else {
         this.imgValidationsMobile.validType = true;
       }

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG" ||
        mimeType == "image/gif")) {
        this.imgValidationsMobile.validType = false;
      } else {
        this.imgValidationsMobile.validType = true;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 300) {
        this.imgValidationsMobile.validSize = false;
      } else {
        this.imgValidationsMobile.validSize = true;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.imgValidationsMobile.validDimension =  ( width !=  800|| height != 300 ) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.imgValidationsMobile.validDimension || 
              !this.imgValidationsMobile.validSize ||
              !this.imgValidationsMobile.validType) {

                let errorMsg = "";
                if (!this.imgValidationsMobile.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.imgValidationsMobile.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.imgValidationsMobile.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            this.previvewImgMobileURL = reader.result;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile1(formData);
          }
        };
      };


    };
    console.log('clicking fileupload....');
    fileUpload.click();
  }


  private uploadFile1(formData: FormData) {
    this.uploadFileSub = this.slidersService.uploadSliderImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message < 100) {
          this.uploadResponse1 = res;
          this.isInProgress1 = true;
        } else {
          this.isInProgress1 = false;
        }
        this.fileNameMobile = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  

  saveSlider() {
    if (this.fileName === '') {
      alert('Please upload the slider image first.');
      return;
    }
    if (this.fileNameMobile === '') {
      alert('Please upload the mobile slider image first.');
      return;
    }
    this.spinner.show();

    this.slider.displayOrder = this.sliderForm.value.displayOrder;
    this.slider.imagePath = 'Sliders/' + this.fileName;
    this.slider.mobileImagePath = 'Sliders/' + this.fileNameMobile;
    this.slider.bigLabel = this.sliderForm.value.bigLabel;
    this.slider.smallLabel = '';

    this.saveSliderSub = this.slidersService.updateSlider(this.slider).subscribe(added => {
      if (added) {
        alert('Slider saved successfully.');
        this.router.navigate(['/admin/sliders']);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.uploadFileSub != null) {
      this.uploadFileSub.unsubscribe();
    }
    if (this.saveSliderSub != null) {
      this.saveSliderSub.unsubscribe();
    }
    this.sliderSub.unsubscribe();
  }

}
