import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NotificationService } from 'src/app/notification.service';
import { AppService } from 'src/app/app.service';
import { Router } from '@angular/router';
import { AppNotification } from 'src/app/models';
import { Subscription, Subject } from 'rxjs';
import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { takeUntil, filter } from 'rxjs/operators';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy, AfterViewInit {
  notifications: AppNotification[] = [];
  notificationsSub: Subscription;
  markNotificationSub: Subscription = null;
  pageNumber = 0;
  pageSize = 10;
  totalPages = 1;
  totalItems = 0;

  query = '';
  allNotificationsFetched = false;
  private unsubscribeAll$: Subject<void> = new Subject();
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;

  constructor(private spinner: NgxSpinnerService, private notificationService: NotificationService,
              private appService: AppService, private router: Router,
              private scrollDispatcher: ScrollDispatcher,
              private changeDetector: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getNotifications(this.pageNumber, true);
  }

  markallasread() {
    this.notificationService.markAllAsRead().subscribe(res => {
      alert('All Notiications Marked as read.');
      this.getNotifications(this.pageNumber, false);
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  getNotifications(pangeNumber, firstCall) {
    this.spinner.show();
    // tslint:disable-next-line:max-line-length
    this.notificationsSub = this.notificationService.getAllNotificationsForUser('admin', pangeNumber, this.pageSize, this.query).subscribe(res => {
      // this.notifications = res.notifications;
      // this.totalItems = res.totalItems;

      if (firstCall) {
        this.totalItems = res.totalItems;
        this.totalPages = Math.ceil(((this.totalItems - 1) / this.pageSize));
      }
      if (res.notifications.length === 0 || res.notifications.length < 5) {
        this.allNotificationsFetched = true;
      }
      this.spinner.hide();
      this.notifications = [...this.notifications, ...res.notifications];
      this.changeDetector.detectChanges();


      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  filter() {
    console.log(this.query);
    this.pageNumber = 0;
    this.notifications = [];
    this.getNotifications(this.pageNumber, true);
  }
  ngAfterViewInit(): void {
    this.scrollDispatcher.scrolled().pipe(takeUntil(this.unsubscribeAll$),
        filter(event => this.virtualScroll.getRenderedRange().end === this.virtualScroll.getDataLength())
        // filter(event => this.virtualScroll.measureScrollOffset('bottom') === 0)
      ).subscribe(event => {
        console.log(); // don't commit it




        // console.log(this.totalPages);
        if (!this.allNotificationsFetched && this.pageNumber < (this.totalPages - 1)) {
          this.getNotifications((++this.pageNumber), false);
        }
        // this.getNotifications((++this.pageNumber));

      }, error => {
        console.error('JP - Error in scrollDispatcher: ', error);
      });

  }

  readAndGotoPage(notification: AppNotification) {
    this.spinner.show();
    this.markNotificationSub = this.notificationService.markNotificationAsRead(notification.id).subscribe(res => {
      this.spinner.hide();
      if (res) {
        this.notificationService.getUnreadNotificationsCountForUser('admin').subscribe(result => {
          this.appService.Data.unreadNotificationsCount = result;

          this.router.navigate([notification.routerLink]);
        });

      }
    }, err => {
      this.spinner.hide();
    });
  }

  gotoPage(notification: AppNotification) {
    this.router.navigate([notification.routerLink]);
  }

  ngOnDestroy() {
    this.notificationsSub.unsubscribe();
    if (this.markNotificationSub != null) {
      this.markNotificationSub.unsubscribe();
    }

    if (this.unsubscribeAll$ != null) {
      this.unsubscribeAll$.next();
      this.unsubscribeAll$.unsubscribe();
    }

  }

  isOrderNotification(notificationTitle) {
    return notificationTitle.toLowerCase().indexOf('order was placed') !== -1;
  }

}
