import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { CategoryService } from 'src/app/category.service';
import { HomepageHeadingService } from 'src/app/homepage-heading.service';
import { HomepageProductService } from 'src/app/homepage-product.service';
import { MenuService } from 'src/app/menu.service';
import { Category, HomePageProduct, Menu, Product, SubCategory } from 'src/app/models';
import { ProductsService } from 'src/app/products.service';
import { SubcategoryService } from 'src/app/subcategory.service';

@Component({
  selector: 'app-edit-homepage-product',
  templateUrl: './edit-homepage-product.component.html',
  styleUrls: ['./edit-homepage-product.component.scss']
})
export class EditHomepageProductComponent implements OnInit, OnDestroy {

  form: FormGroup;
  menus: Menu[];
  categories: Category[];
  subcategories: SubCategory[];
  products: Product[];
  homePageProduct: HomePageProduct;
  menuID: number;

  menusSub: Subscription;
  categoriesSub: Subscription = null;
  subcategoriesSub: Subscription = null;
  productsSub: Subscription = null;
  addHomePageProductSub: Subscription = null;

  constructor(private homepageHeadingService: HomepageHeadingService,
              private homePageProductService: HomepageProductService,
              private categoryService: CategoryService,
              private subcategoryService: SubcategoryService,
              private menuService: MenuService,
              private productService: ProductsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.homePageProductService.getHomePageProduct(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.spinner.hide();
      this.homePageProduct = res;
      this.form.patchValue({
        productOrder: res.productOrder
      });
      this.bindMenus();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });

  }

  bindCategories(menuId: number) {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(menuId).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
      this.bindSubCategories(this.homePageProduct.product.categoryID);
    });
  }

  bindSubCategories(categoryId: number) {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(categoryId).subscribe(res => {
      this.spinner.hide();
      this.subcategories = res;
      this.form.patchValue({
        menu: this.menuID,
        category: this.homePageProduct.product.categoryID,
        subcategory: this.homePageProduct.product.subcategoryID
      });

      this.spinner.show();
      // tslint:disable-next-line:no-shadowed-variable
      this.productsSub = this.productService.getBySubcategory(this.homePageProduct.product.subcategoryID).subscribe(res => {
        this.products = res;
        this.form.patchValue({
          product: this.homePageProduct.product.id
        });
        this.spinner.hide();
      });
    });
  }


  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.spinner.hide();
      this.menus = res;
      this.spinner.show();
      // tslint:disable-next-line:no-shadowed-variable
      this.categoryService.getById(this.homePageProduct.product.categoryID).subscribe(res => {
        this.spinner.hide();
        this.menuID = res.menuID;
        this.bindCategories(this.menuID);
      });
    });
  }

  onMenuSelected() {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(this.form.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onCategorySelected() {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(this.form.value.category).subscribe(res => {
      this.subcategories = res;
      this.spinner.hide();
    });
  }

  onSubcategorySelected() {
    this.spinner.show();
    this.productsSub = this.productService.getBySubcategory(this.form.value.subcategory).subscribe(res => {
      this.products = res;
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      menu: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      product: ['', Validators.required],
      productOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveProduct() {
    this.spinner.show();
    this.homePageProduct.productID = this.form.value.product;
    this.homePageProduct.productOrder = this.form.value.productOrder;

    this.homePageProductService.updateHomePageProduct(this.homePageProduct).subscribe(added => {
      if (added) {
        alert('Homepage Product saved successfully.');
        this.spinner.hide();
        this.router.navigate(['/admin/homepage-products/' + this.homePageProduct.headingID]);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.menusSub.unsubscribe();
    if (this.categoriesSub != null) {
      this.categoriesSub.unsubscribe();
    }
    if (this.subcategoriesSub != null) {
      this.subcategoriesSub.unsubscribe();
    }
    if (this.productsSub != null) {
      this.productsSub.unsubscribe();
    }
    if (this.addHomePageProductSub != null) {
      this.addHomePageProductSub.unsubscribe();
    }
  }

}
