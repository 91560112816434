import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { TopCategory } from './models';

@Injectable({
  providedIn: 'root'
})
export class TopCategoryService {

  constructor(private http: HttpClient) { }

  getTopCategories(): Observable<any[]> {
    const url = BaseApiService.baseApiUrl + 'api/TopCategory/GetTopCategories';

    return this.http.get<any[]>(url);
  }
  getTopCategory(id: number): Observable<TopCategory> {
    const url = BaseApiService.baseApiUrl + 'api/TopCategory/Get/' + id;

    return this.http.get<TopCategory>(url);
  }
  addTopCategory(topCategory: TopCategory): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/TopCategory/Post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, topCategory, {headers});
  }

  updateTopCategory(topCategory: TopCategory): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/TopCategory/Put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, topCategory, {headers});
  }
  deleteTopCategory(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/TopCategory/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }

    // upload product image
    uploadTopCategoryImage(formData: FormData) {
      const url = BaseApiService.baseApiUrl + 'api/TopCategory/UploadTopCategoryImage';
  
      const headers = new HttpHeaders();
  
      return this.http.post<any>(url, formData, {
        reportProgress: true,
        observe: 'events'
      }).pipe(map((event) => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            const progress = Math.round(100 * event.loaded / event.total);
            return { status: 'progress', message: progress };
  
          case HttpEventType.Response:
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      })
      );
    }
}
