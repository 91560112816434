import { DatePipe } from '@angular/common';
import { HttpErrorResponse, HttpEventType } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BaseApiService } from 'src/app/base-api.service';
import { User, CustomOrder, CustomOrderHistory, CustomOrderDetails, RequestOrder, RequestOrderDetails, RequestOrderHistory } from 'src/app/models';
import { OrdersService } from 'src/app/orders.service';
import { UsersService } from 'src/app/users.service';

@Component({
  selector: 'app-request-order-details',
  templateUrl: './request-order-details.component.html',
  styleUrls: ['./request-order-details.component.scss']
})
export class RequestOrderDetailsComponent implements OnInit {
  statusForm: FormGroup;
  commentsForm: FormGroup;
  logisticForm: FormGroup;
  baseApiUrl = BaseApiService.baseApiUrl;
  percentComplete = 0;
  isInProgress = false;
  isOrderNotPending = true;
  users: User[] = [];
  order: RequestOrder = null;
  orderHistories: RequestOrderHistory[];
  orderDetails: RequestOrderDetails[];
  orderSub: Subscription;
  orderHistoriesSub: Subscription;
  orderDetailsSub: Subscription;
  saveStatusSub: Subscription = null;
  saveCommentsSub: Subscription = null;
  confirmOrderSub: Subscription = null;
  jurisdiction = '';
  logisticPartnerName = '';
  newShippingCharges = 0;
  discount = 0;
  constructor(private fbComments: FormBuilder, private fbStatus: FormBuilder,
    private fbLogistics: FormBuilder, private usersService: UsersService,
    private ordersService: OrdersService, private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService, private router: Router,
    private datePipe: DatePipe) { }

  ngOnInit(): void {
    this.statusForm = this.fbStatus.group({
      status: ['', Validators.required]
    });
    this.commentsForm = this.fbComments.group({
      comments: ['', Validators.required],
      date: ['', Validators.required]
    });

    this.logisticForm = this.fbLogistics.group({
      logisticPartner: ['', Validators.required],
      jurisdiction: ['']
    });

    this.bindOrderDetails();
  }

  bindOrderDetails() {
    this.spinner.show();
    this.orderSub = this.ordersService.getRequestOrder(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.order = res;
      console.log('Order........', res);
      this.isOrderNotPending = res.orderStatus !== 'Pending';
      this.newShippingCharges = res.shippingCharges;
      this.ordersService.getRequestOrderDetails(+this.activatedRoute.snapshot.params.id).subscribe(resOrderDetails => {
        this.orderDetails = resOrderDetails;
        console.log(resOrderDetails);
        this.ordersService.getRequestOrderHistories(+this.activatedRoute.snapshot.params.id).subscribe(resOrderHistories => {
          this.orderHistories = resOrderHistories;
          this.spinner.hide();
          this.getLogisticPartnerDetails();
        });
      });
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  getLogisticPartnerDetails() {
    if (this.order.logisticPartnerUsername !== null) {
      this.spinner.show();
      this.usersService.getUserByUsername(this.order.logisticPartnerUsername).subscribe(res => {
        this.spinner.hide();
        this.logisticPartnerName = res.name;
        this.jurisdiction = res.jurisdiction;
      }, err => {
        this.spinner.hide();
      });
    }
  }

  changeStatus() {
    this.spinner.show();
    this.saveStatusSub = this.ordersService.updateRequestOrderStatus(+this.activatedRoute.snapshot.params.id, this.statusForm.value.status).subscribe(res => {
      if (res.added) {
        alert('Status updated successfully.');
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
      this.bindOrderDetails();
    }, error => {
      this.spinner.hide();
      if (error instanceof HttpErrorResponse) {
        if (error.status === 401) {
          this.router.navigate(['/sign-in']);
        }
      }
    });
  }

  addComments() {
    this.spinner.show();
    this.saveCommentsSub = this.ordersService.addRequestOrderComments(+this.activatedRoute.snapshot.params.id,
      this.datePipe.transform(this.commentsForm.value.date, 'MM/dd/yyyy'),
      this.commentsForm.value.comments).subscribe(res => {
        if (res.added) {
          alert('Comments added successfully.');
        } else {
          alert('Error occured. Please try again...');
        }
        this.spinner.hide();
        this.bindOrderDetails();
      }, error => {
        this.spinner.hide();
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401) {
            this.router.navigate(['/sign-in']);
          }
        }
      });
  }

  confirmOrder() {
    // this.spinner.show();
    // this.confirmOrderSub = this.ordersService.confirmOrder(+this.activatedRoute.snapshot.params.id).subscribe(res => {
    //   if (res.added) {
    //     alert('Order confirmed successfully.');
    //   } else {
    //     alert('Error occured. Please try again...');
    //   }
    //   this.spinner.hide();
    //   this.bindOrderDetails();
    // }, error => {
    //   this.spinner.hide();
    //   if (error instanceof HttpErrorResponse) {
    //     if (error.status === 401) {
    //       this.router.navigate(['/sign-in']);
    //     }
    //   }
    // });
  }

  ngOnDestroy() {
    if (this.saveCommentsSub != null) {
      this.saveCommentsSub.unsubscribe();
    }
    if (this.saveStatusSub != null) {
      this.saveStatusSub.unsubscribe();
    }
    if (this.confirmOrderSub != null) {
      this.confirmOrderSub.unsubscribe();
    }
    if (this.orderSub != null) {
      this.orderSub.unsubscribe();
    }
    if (this.orderHistoriesSub != null) {
      this.orderHistoriesSub.unsubscribe();
    }
    if (this.orderDetailsSub != null) {
      this.orderDetailsSub.unsubscribe();
    }
  }

  getLogisticPartners() {
    this.spinner.show();
    this.usersService.getLogisticPartnersByJurisdiction(this.logisticForm.value.jurisdiction).subscribe(res => {
      this.users = res;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  assignOrderToLogisticPartner() {

    this.spinner.show();
    this.ordersService.assignRequestOrderToLogisticPartner(this.logisticForm.value.logisticPartner,
      +this.activatedRoute.snapshot.params.id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert(res.message);
          this.bindOrderDetails();
        } else {
          alert(res.message);
        }
      }, err => {
        this.spinner.hide();
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.router.navigate(['/sign-in']);
          } else {
            alert('Error occured. Please try again...');
          }
        } else {
          alert('Error occured. Please try again...');
        }

      });

  }

  downloadBill() {
    this.spinner.show();
    this.isInProgress = true;
    this.ordersService.downloadRequestOrderBill(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.spinner.hide();
      switch (res.type) {
        case HttpEventType.DownloadProgress:
          this.isInProgress = true;
          this.percentComplete = Math.round((res.loaded / res.total) * 100);
          break;
        case HttpEventType.Response:
          this.isInProgress = false;
          this.percentComplete = 0;
          const downloadedFile = new Blob([res.body]);
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = 'Order_' + this.activatedRoute.snapshot.params.id + '.pdf';
          a.href = URL.createObjectURL(downloadedFile);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
          break;
      }
    });
  }

  returnOrder() {
    this.spinner.show();
    this.ordersService.returnRequestOrder(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.spinner.hide();
      alert(res.message);
      this.bindOrderDetails();
    }, err => {
      this.spinner.hide();
      if (err instanceof HttpErrorResponse) {
        if (err.status == 401) {
          this.router.navigate(['/sign-in']);
        } else {
          alert('Error occured. Please try again...');
        }
      } else {
        alert('Error occured. Please try again...');
      }
    });
  }

  cancelOrder() {
    const done = confirm('Are you sure you want to cancel this order?');
    if (done) {
      const reason = (document.getElementById('reason') as any).value;
      this.spinner.show();
      this.ordersService.cancelRequestOrder(+this.activatedRoute.snapshot.params.id,
        reason, true).subscribe(res => {
          this.spinner.hide();
          if (res.added) {
            alert(res.message);
            this.bindOrderDetails();
          } else {
            alert(res.message);
          }
        }, err => {
          this.spinner.hide();
          if (err instanceof HttpErrorResponse) {
            if (err.status == 401) {
              this.router.navigate(['/sign-in']);
            } else {
              alert('Error occured. Please try again...');
            }
          } else {
            alert('Error occured. Please try again...');
          }
        });
    }
  }

  getProductImage(product, color) {
    return product.productImages.filter(p => p.colorName === color).length > 0 ?
      this.baseApiUrl + product.productImages.filter(p => p.colorName === color)[0].imagePath :
      this.baseApiUrl + product.productImages[0].imagePath;
  }

  removeProductFromOrder(id) {
    const deleted = confirm('Are you sure you want to remove this product?');
    if (deleted) {
      this.spinner.show();
      this.ordersService.removeProductFromRequestOrder(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert(res.message);
          this.bindOrderDetails();
        } else {
          alert(res.message);
        }
      }, err => {
        this.spinner.hide();
        alert('Error occured. Please try again...');
      });
    }
  }

  updateShippingCharges() {
    this.spinner.show();
    this.ordersService.updateRequestOrderShippingCharges(+this.activatedRoute.snapshot.params.id,
      this.newShippingCharges)
      .subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert(res.message);
          this.bindOrderDetails();
        } else {
          alert(res.message);
        }
      }, err => {
        if (err.status === 401) {
          this.router.navigateByUrl('/');
        } else {
          alert('Error occured. Please try again...');
        }
      });
  }

  updatePaymentShippingDetails() {
    this.spinner.show();
    this.ordersService.updateRequestOrderPaymentDetails(this.order.id, this.order.paymentMethod,
      this.order.deliveryMode)
      .subscribe(res => {
        if (res.added) {
          alert(res.message);
          this.bindOrderDetails();
        } else {
          alert(res.message);
        }
      }, err => {
        if (err.status === 401) {
          this.router.navigateByUrl('/');
        } else {
          alert('Error occured. Please try again...');
        }
      });
  }

  finalizeOrder() {
    this.spinner.show();
    this.ordersService.finalizeRequestOrder(this.order.id, this.discount)
    .subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        alert(res.message);
        this.bindOrderDetails();
      } else {
        alert(res.message);
      }
    }, err => {
      this.spinner.hide();
      if (err.status === 401) {
        this.router.navigateByUrl('/');
      } else {
        alert('Error occured. Please try again...');
      }
    });
  }

  dispatchOrder() {
    this.spinner.show();
    this.ordersService.confirmRequestOrder(this.order.id,
      this.order.sellerDispatchCode).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert(res.message);
          this.bindOrderDetails();
        } else {
          alert(res.message);
        }
      }, err => {
        this.spinner.hide();
        if (err.status === 401) {
          this.router.navigateByUrl('/');
        } else {
          alert('Error occured. Please try again...');
        }
      });
  }
}
