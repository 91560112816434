import { Stock } from './../../models';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { StockService } from './../../stock.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class StocksComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  stocks: Stock[] = [];
  stocksSub: Subscription;
  productid: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.stocks);
  displayedColumns: string[] = ['color', 'size', 'actionEdit'];

  constructor(private stockService: StockService,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindStocks();
  }

  bindStocks() {
    this.spinner.show();
    this.productid = this.activatedRoute.snapshot.params.id;
    this.stocksSub = this.stockService.getByProduct(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.stocks = res;
      this.dataSource = new MatTableDataSource<any>(this.stocks);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.stocksSub.unsubscribe();
  }

}
