import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubcategoryService } from 'src/app/subcategory.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Menu, SubCategory } from 'src/app/models';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { BaseApiService } from 'src/app/base-api.service';
import { CategoryService } from 'src/app/category.service';
import { MenuService } from 'src/app/menu.service';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-edit-subcategory',
  templateUrl: './edit-subcategory.component.html',
  styleUrls: ['./edit-subcategory.component.scss']
})
export class EditSubcategoryComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  subcategory: any;
  categories: any[];
  menus: Menu[];
  form: FormGroup;
  subcategorySub: Subscription;
  saveSubcategorySub: Subscription = null;
  previvewImgURL: any;
  text = 'Select Subategory Image ';
  file: FileUploadModel;
  isInProgress = false;
  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;
  imgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  constructor(private subcategoryService: SubcategoryService,
              private menuService: MenuService,
              private categoryService: CategoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindSubCategory();
  }

  bindMenus() {
    this.spinner.show();
    this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
      this.bindCategories(this.subcategory.menuID);
    });
  }

  bindCategories(menuId: number) {
    this.spinner.show();
    this.categoryService.getByMenu(menuId).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  bindSubCategory() {
    this.spinner.show();
    this.subcategorySub = this.subcategoryService.getById(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.subcategory = res;
      this.form.patchValue({
        subcategoryName: res.subcategoryName,
        subcategoryOrder: res.subcategoryOrder,
        menu: res.menuID,
        category: res.categoryID
      });
      this.bindMenus();
      this.fileName = res.imagePath == null ? '' : this.subcategory.imagePath.substring(res.imagePath.lastIndexOf('/') + 1);
      console.log(this.fileName);
      this.spinner.hide();
    }, error => {
      this.subcategory = null;
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      menu: ['', Validators.required],
      category: ['', Validators.required],
      subcategoryName: ['', Validators.required],
      subcategoryOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onMenuSelected() {
    this.spinner.show();
    this.categoryService.getByMenu(this.form.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.imgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];  
        
      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.imgValidations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG" ||
       mimeType == "image/svg+xml") ) {
        this.imgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 120) {
        this.imgValidations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.imgValidations.validDimension =  ( width !=  100|| height != 100 ) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.imgValidations.validDimension || 
              !this.imgValidations.validSize ||
              !this.imgValidations.validType) {

                let errorMsg = "";
                if (!this.imgValidations.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.imgValidations.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.imgValidations.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            this.previvewImgURL = reader.result;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile(formData);
          }
        };
      };


    };
    console.log('clicking fileupload....');
    fileUpload.click();
  }



  private uploadFile(formData: FormData) {
    this.subcategoryService.uploadSubcategoryImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        }
        this.fileName = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  saveSubCategory() {
    if (this.fileName === '') {
      alert('Please upload the file first.');
      return;
    }
    this.spinner.show();
    if (this.subcategory != null) {
      this.subcategory.subcategoryName = this.form.value.subcategoryName;
      this.subcategory.subcategoryOrder = this.form.value.subcategoryOrder;
      this.subcategory.imagePath = 'Subcategories/' + this.fileName;
      this.subcategory.categoryID = this.form.value.category;

      this.saveSubcategorySub = this.subcategoryService.updateSubCategory(this.subcategory).subscribe(added => {
        if (added) {
          alert('Subcategory saved successfully.');
        } else {
          alert('Error occured. Please try again...');
        }
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    } else {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    }
  }

  ngOnDestroy() {
    if (this.saveSubcategorySub != null) {
      this.saveSubcategorySub.unsubscribe();
    }
    this.subcategorySub.unsubscribe();
  }
}
