import { Component, OnInit, ViewChild } from '@angular/core';
import { Product, User } from 'src/app/models';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ProductsService } from 'src/app/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubcategoryService } from 'src/app/subcategory.service';
import { CategoryService } from 'src/app/category.service';
import { MenuService } from 'src/app/menu.service';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ProductImage } from 'src/app/models';
import { ProductImageService } from 'src/app/product-image.service';
import Swal from 'sweetalert2';
import { HttpErrorResponse } from '@angular/common/http';
@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss']
})
export class ProductInfoComponent implements OnInit {
  user: any = null;
  constructor(private usersService: UsersService, private spinner: NgxSpinnerService,
    private productsService: ProductsService,
    private menuService: MenuService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private productImageService: ProductImageService,
    private router: Router,
    private activatedRoute: ActivatedRoute) { }
  menusSub: Subscription;
  categoriesSub: Subscription;
  subcategoriesSub: Subscription;
  productSub: Subscription;
  product: any;
  menu: string;
  category: string;
  subcategory: string;


  productId: string;
  productImages: ProductImage[] = [];
 
  baseApiUrl = BaseApiService.baseApiUrl;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  dataSource = new MatTableDataSource<ProductImage>(this.productImages);
  displayedColumns: string[] = ['imagePath', 'color', 'actionEdit', 'actionDelete'];

  productImagesObject : any;
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  currentIndex : number = -1;
  
  ngOnInit() {
    this.bindProduct();
  }

  bindProduct() {
    this.spinner.show();
    this.productId = this.activatedRoute.snapshot.params.id;
    this.bindProductImages();
    this.productSub = this.productsService.getById(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.product = res;

      console.log(this.product);
      this.spinner.hide();
      this.bindMenus();
    });
  }

  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      // console.log(res);
      // this.menus = res;
      // this.spinner.hide();
      res.forEach( m => {
        if (this.product.menuID == m.id) {
          this.menu = m.menuName;
        }
      });
      this.bindCategories(this.product.menuID);
    });
  }

  bindCategories(menuId: number) {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(menuId).subscribe(res => {
      // console.log(res);
      res.forEach( m => {
        if (this.product.categoryID == m.id) {
          this.category = m.categoryName;
        }
      });
      this.spinner.hide();
      this.bindSubCategories(this.product.categoryID);
    });
  }

  bindSubCategories(categoryId: number) {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(categoryId).subscribe(res => {
      // this.subcategories = res;
      // console.log(res);

      res.forEach( m => {
        if (this.product.subCategoryID == m.id) {
          this.subcategory = m.subcategoryName;
        }
      });

      this.spinner.hide();
    });
  }

  
  saveProduct(val) {
    this.spinner.show();

    this.productsService.toggleProductActive(+this.activatedRoute.snapshot.params.id, val).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.bindProduct();
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }



  bindProductImages() {
    this.spinner.show();
    this.productImageService.getProductImages(+this.productId).subscribe(res => {
      this.productImages = res;
      console.log('Res:', res);

    this.productImagesObject = [];
    this.productImages.forEach( (element, index) => {
      let imgData = {
        image: this.baseApiUrl + element.imagePath,
        thumbImage: this.baseApiUrl + element.imagePath,
        title: element.color
      };
      this.productImagesObject.push(imgData);
      element['index'] = index;
      this[index] = element;
    },this);
    console.log(this.productImages);
      this.dataSource = new MatTableDataSource<ProductImage>(this.productImages);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  deleteProductImage(id) {
    const c = confirm('Are you sure to delete this image?');
    if (c) {
      this.spinner.show();
      this.productImageService.deleteProductImage(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          Swal.fire('Message', res.message, 'success');
          this.bindProductImages();
        } else {
          Swal.fire('Error', res.message, 'error');
        }
      }, err => {
        this.spinner.hide();
        if (err instanceof HttpErrorResponse) {
          if (err.status == 401) {
            this.router.navigate(['/sign-in']);
          } else {
            
          }
        } else {
          Swal.fire('Error', 'Error occured. Please try again...', 'error');
        }
      });
    }
  }


    showLightbox(index) {
      this.selectedImageIndex = index;
      this.showFlag = true;
  }

  closeEventHandler() {
      this.showFlag = false;
      this.currentIndex = -1;
  }
}
