import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-seller-receipt',
  templateUrl: './seller-receipt.component.html',
  styleUrls: ['./seller-receipt.component.scss']
})
export class SellerReceiptComponent implements OnInit {
  form: FormGroup;
  toDate: Date;
  fromDate: Date;
  constructor(private activatedRoute: ActivatedRoute,
    private datePipe: DatePipe, private fb: FormBuilder) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      type: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  downloadReceipt() {
    if (this.form.value.type == "0") {
      window.open("https://api.jhelumlogistics.com/Download/DownloadSellerPaymentReceipt?username=" +
        this.activatedRoute.snapshot.params.name +
        "&startDate=" +
        this.datePipe.transform(this.fromDate, 'MM/dd/yyyy') +
        "&endDate=" + this.datePipe.transform(this.toDate, 'MM/dd/yyyy'));
    } else {
      window.open("https://api.jhelumlogistics.com/Download/DownloadSellerPaymentReceiptWithoutCommission?username=" +
      this.activatedRoute.snapshot.params.name +
      "&startDate=" +
      this.datePipe.transform(this.fromDate, 'MM/dd/yyyy') +
      "&endDate=" + this.datePipe.transform(this.toDate, 'MM/dd/yyyy'))
    }
  }

  downloadCustomOrdersReceipt() {
    window.open("https://api.jhelumlogistics.com/Download/DownloadSellerPaymentReceiptCustomOrders?username=" +
        this.activatedRoute.snapshot.params.name +
        "&startDate=" +
        this.datePipe.transform(this.fromDate, 'MM/dd/yyyy') +
        "&endDate=" + this.datePipe.transform(this.toDate, 'MM/dd/yyyy'));
  }

}
