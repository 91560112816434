import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Country, State, TokenAuth, User, UserDTO } from 'src/app/models';
import { UsersService } from 'src/app/users.service';
import { AppService } from 'src/app/app.service';
import { CountriesService } from 'src/app/countries.service';
import { StatesService } from 'src/app/states.service';
import { matchingPasswords } from 'src/app/theme/utils/app-validators';

@Component({
  selector: 'app-add-seller',
  templateUrl: './seller-register.component.html',
  styleUrls: ['./seller-register.component.scss']
})
export class SellerRegisterComponent implements OnInit, OnDestroy {
  countries: Country[];
  states: State[];
  countryId: string = '';
  stateId: string = '';
  mobileVerified: boolean = false;
  usernameLogin: string;
  passwordLogin: string;
  form: FormGroup;
  saveSub: Subscription = null;
  loginSub: Subscription = null;
  countriesSub: Subscription;
  statesSub: Subscription;
  verifyCaptchaSub: Subscription = null;


  secretKey = '6Lftsj4UAAAAAFx7LVc8XxTugqEGEJwq5l-wio4_';

  captchaVerified = false;

  constructor(private usersService: UsersService,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    public appService: AppService,
    private countriesService: CountriesService,
    private statesService: StatesService,
    private snackBar: MatSnackBar,
    private router: Router,
    private titleService: Title) {
    // initialize the form
    this.createForm();
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', Validators.email],
      mobile: ['', Validators.pattern('[0-9]{9,20}')],
      country: new FormControl({ value: '', disabled: true }, Validators.required),
      state: new FormControl({ value: '', disabled: true }, Validators.required),
      businessName: ['', Validators.required],
      businessAddress: ['', Validators.required],
      about: ['', Validators.required],
      // refundWithin: ['', Validators.pattern('[0-9]{1,2}')],
      sellerType: ['', Validators.required],
      shipmentType: ['', Validators.required],
      logisticType: ['', Validators.required],
      gstRegistrationNumber: [''],
      city: new FormControl({ value: 'Srinagar', disabled: true }),
      pincode: ['', Validators.pattern('[0-9]{5,8}')],
      username: ['', Validators.required],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, { validator: matchingPasswords('password', 'confirmPassword') });
  }

  ngOnInit(): void {
    this.titleService.setTitle('Seller Registration - JhelumCart Online Pvt. Ltd.')
    this.bindCountries();
  }

  bindCountries() {
    this.spinner.show();
    this.countriesSub = this.countriesService.getCountries().subscribe(res => {
      this.countries = res;
      this.form.patchValue({
        country: res.filter(c => c.id === 101)[0].id
      });
      this.spinner.hide();
      this.bindStates();
    }, err => {
      this.spinner.hide();
    });
  }

  onCountrySelected() {
    this.bindStates();
  }

  bindStates() {
    if (this.statesSub != null ) {
      this.statesSub.unsubscribe();
    }
    this.spinner.show();
    this.statesSub = this.statesService.getByCountry(+this.form.controls['country'].value).subscribe(res => {
      this.states = res;
      this.form.patchValue({
        state: res.filter(s => s.id === 15)[0].id
      });
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  resolved(captchaResponse: string) {
    // console.log(`Resolved captcha with response: ${captchaResponse}`);
    if (captchaResponse != null) {
      if (this.verifyCaptchaSub != null) {
        this.verifyCaptchaSub.unsubscribe();
      }
      this.verifyCaptchaSub = this.usersService.verifyCaptcha(captchaResponse, this.secretKey).subscribe(res => {
        if (res) {
          this.captchaVerified = true;
        } else {
          this.captchaVerified = false;
        }
      });
    } else {
      this.captchaVerified = false;
    }
  }

  removeSpaces(str) {
    return str.replace(/ /g, '');
  }

  register() {


    const user: User = {
      id: '',
      role: 'Seller',
      name: this.form.value.name,
      email: this.form.value.email,
      mobile: this.form.value.mobile,
      username: this.removeSpaces(this.form.value.username),
      password: this.form.value.password,
      countryID: +this.form.controls['country'].value,
      stateID: +this.form.controls['state'].value,
      city: this.form.controls['city'].value,
      businessName: this.form.value.businessName,
      businessAddress: this.form.value.businessAddress,
      about: this.form.value.about,
      businessLogoPath1: '',
      businessLogoPath2: '',
      businessLogoPath3: '',
      businessLogoPath4: '',
      registrationDocumentImagePath: '',
      aadharCardImagePath: '',
      panCardImagePath: '',
      pinCode: this.form.value.pincode,
      refundWithin: 0,
      sellerType: this.form.value.sellerType,
      gstRegistrationNumber: this.form.value.gstRegistrationNumber,
      isActive: false,
      shipmentType: this.form.value.shipmentType,
      logisticType: this.form.value.logisticType
    };


    const userDTO: UserDTO = {
      user,
      otp: null,
      sessionID: null
    };

    this.spinner.show();
    this.saveSub = this.usersService.addUser(userDTO).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        this.form.reset();
        alert(res.message);
      } else {
        alert(res.message);
      }
    }, err => {
      this.spinner.hide();
      this.snackBar.open('Error occured. Please try again...', '×', { panelClass: 'error', verticalPosition: 'top', duration: 3000 });
    });
  }

  ngOnDestroy() {
    if (this.saveSub != null) {
      this.saveSub.unsubscribe();
    }
    if (this.verifyCaptchaSub != null) {
      this.verifyCaptchaSub.unsubscribe();
    }
    if (this.loginSub != null) {
      this.loginSub.unsubscribe();
    }
    this.countriesSub.unsubscribe();
    this.statesSub.unsubscribe();
  }

}
