import { Slider } from './models';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SlidersService {

  constructor(private http: HttpClient) { }

  // upload product image
  uploadSliderImage(formData: FormData) {
    const url = BaseApiService.baseApiUrl + 'api/Slider/UploadSliderImage';

    // let headers = new HttpHeaders({
    //   'Content-Type': 'application/json'
    // });
    const headers = new HttpHeaders();
    // let options = {
    //     headers: headers
    // }
    // let token = JSON.parse(localStorage.getItem('token'));
    // formData.append('username', token.username);

    return this.http.post<any>(url, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;
        default:
          return `Unhandled event: ${event.type}`;
      }
    })
    );
  }


  // adds the slider to the database
  addSlider(slider: Slider): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/slider/post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});


    return this.http.post<boolean>(url, slider, {headers});
  }

  // updates the slider
  updateSlider(slider: Slider): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/slider/put';
    // const data = {
    //   id: slider.id,
    //   imagePath: 'Sliders/' + slider.imagePath,
    //   mobileImagePath: 'Sliders/' + slider.mobileImagePath,
    //   tabImagePath: 'Sliders/' + slider.tabImagePath,
    //   displayOrder: slider.displayOrder,
    //   bigLabel: slider.bigLabel,
    //   smallLabel: slider.smallLabel
    // };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<boolean>(url, slider, {headers});
  }

  // delete a slider
  deleteSlider(id: number): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/slider/delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<boolean>(url, {headers});
  }

  // gets all sliders from api
  getSliders(): Observable<Slider[]> {
    const url = BaseApiService.baseApiUrl + 'api/slider/get';
    return this.http.get<Slider[]>(url);
  }

  // get slider by id
  getSlider(id: number): Observable<Slider> {
    const url = BaseApiService.baseApiUrl + 'api/slider/get/' + id;
    return this.http.get<Slider>(url);
  }
}
