import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShippingPincodesService } from 'src/app/shipping-pincodes.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-pincode',
  templateUrl: './add-pincode.component.html',
  styleUrls: ['./add-pincode.component.scss']
})
export class AddPincodeComponent implements OnInit, OnDestroy {

  form: FormGroup;
  addShippingPincodeSub: Subscription;
  constructor(private shippingPincodesService: ShippingPincodesService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      fromPincode: ['', Validators.required],
      toPinCode: ['', Validators.required],
      state: ['', Validators.required],
      district: ['', Validators.required],
      codAvailable: [''],
      onlinePaymentAvailable: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  savePincode() {
    this.spinner.show();
    this.addShippingPincodeSub = this.shippingPincodesService.addShippingPincode(this.form.value).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/admin/pincodes']);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.addShippingPincodeSub.unsubscribe();
  }

}
