import { HomePageProduct } from './../../models';
import { ProductsService } from './../../products.service';
import { MenuService } from './../../menu.service';
import { SubcategoryService } from './../../subcategory.service';
import { CategoryService } from './../../category.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HomepageHeadingService } from 'src/app/homepage-heading.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomePageHeading, Menu, Category, SubCategory, Product } from 'src/app/models';
import { HomepageProductService } from 'src/app/homepage-product.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-homepage-product',
  templateUrl: './add-homepage-product.component.html',
  styleUrls: ['./add-homepage-product.component.scss']
})
export class AddHomepageProductComponent implements OnInit, OnDestroy {

  form: FormGroup;
  menus: Menu[];
  categories: Category[];
  subcategories: SubCategory[];
  products: Product[];

  menusSub: Subscription;
  categoriesSub: Subscription = null;
  subcategoriesSub: Subscription = null;
  productsSub: Subscription = null;
  addHomePageProductSub: Subscription = null;

  constructor(private homepageHeadingService: HomepageHeadingService,
              private homePageProductService: HomepageProductService,
              private categoryService: CategoryService,
              private subcategoryService: SubcategoryService,
              private menuService: MenuService,
              private productService: ProductsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindMenus();
  }


  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
    });
  }

  onMenuSelected() {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(this.form.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onCategorySelected() {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(this.form.value.category).subscribe(res => {
      this.subcategories = res;
      this.spinner.hide();
    });
  }

  onSubcategorySelected() {
    this.spinner.show();
    this.productsSub = this.productService.getBySubcategory(this.form.value.subcategory).subscribe(res => {
      this.products = res;
      this.spinner.hide();
    });
  }

  createForm() {
    this.form = this.fb.group({
      menu: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
      product: ['', Validators.required],
      productOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveProduct() {
    this.spinner.show();
    const product: HomePageProduct = {
      productID: this.form.value.product,
      productOrder: this.form.value.productOrder,
      headingID: +this.activatedRoute.snapshot.params.id
    };
    this.homePageProductService.addHomePageProduct(product).subscribe(added => {
      if (added) {
        alert('Homepage Product saved successfully.');
        this.spinner.hide();
        this.router.navigate(['/admin/homepage-products/' + (+this.activatedRoute.snapshot.params.id)]);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.menusSub.unsubscribe();
    if (this.categoriesSub != null) {
      this.categoriesSub.unsubscribe();
    }
    if (this.subcategoriesSub != null) {
      this.subcategoriesSub.unsubscribe();
    }
    if (this.productsSub != null) {
      this.productsSub.unsubscribe();
    }
    if (this.addHomePageProductSub != null) {
      this.addHomePageProductSub.unsubscribe();
    }
  }

}
