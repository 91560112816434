import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from 'src/app/users.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { BaseApiService } from 'src/app/base-api.service';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CashInflowService } from 'src/app/cash-inflow.service';
import { DatePipe } from '@angular/common';
import { ExpensesService } from 'src/app/expenses.service';

@Component({
  selector: 'app-logistic-partner-details',
  templateUrl: './logistic-partner-details.component.html',
  styleUrls: ['./logistic-partner-details.component.scss']
})
export class LogisticPartnerDetailsComponent implements OnInit, OnDestroy {
  baseApiUrl = BaseApiService.baseApiUrl;
  form: FormGroup;
  formRevenue: FormGroup;
  user: any = null;
  userSub: Subscription;
  activateSub: Subscription = null;
  deactivateSub: Subscription = null;
  loading : boolean;
  jurisdiction = 'East Srinagar';
  jurisdictions = [
    {value: 'East Srinagar' },
    {value: 'West Srinagar'},
    {value: 'North Srinagar'},
    {value: 'South Srinagar'},
  ];

  productImagesObject : any;
  showFlag: boolean = false;
  selectedImageIndex: number = -1;
  currentIndex : number = -1;
  toDate: Date;
  fromDate: Date;
  grossCashCollection = 0;
  expenses = 0;
  netCashCollection = 0;
  userExpenses = 0;
  revenue = 0;
  profit = 0;

  constructor(private usersService: UsersService, private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute, private router: Router,
              private fb: FormBuilder, private cashInflowService: CashInflowService,
              private datePipe: DatePipe, private expensesService: ExpensesService) {
                this.createForm();
               }

  ngOnInit(): void {
    this.loading = true;
    this.bindUser();
  }

  bindUser() {
    this.productImagesObject = [];
    const username = this.activatedRoute.snapshot.params.username;
    this.spinner.show();
     this.userSub = this.usersService.getUserByUsername(username).subscribe(res => {
      this.user = res;
      this.loading = false;
      // console.log(this.user);
      this.jurisdiction = this.user.jurisdiction;
      // console.log(this.loading);

      let imgData1 = {
        image: this.baseApiUrl + this.user.registrationDocumentImagePath,
        thumbImage: this.baseApiUrl + this.user.registrationDocumentImagePath,
        title: 'Vehicle Registration'
      };
      this.productImagesObject.push(imgData1);

      let imgData2 = {
        image: this.baseApiUrl + this.user.licenseImagePath,
        thumbImage: this.baseApiUrl + this.user.licenseImagePath,
        title: 'Driving License'
      };
      this.productImagesObject.push(imgData2);
      
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  setDefaultRage() {
    this.toDate = new Date();
    const d = new Date();
    d.setDate(this.toDate.getDate() - 10);
    this.fromDate = d;
  }

  createForm() {
    this.form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      status: ['']
    });
    this.formRevenue = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      status: ['']
    });
  }


  saveProfile() {

    this.user.isActive = true;
    this.user.modifiedBy = 'admin';
    this.user.jurisdiction = this.jurisdiction;
    console.log(this.user);
    this.spinner.show();
    this.userSub = this.usersService.updateUser(this.user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        Swal.fire('Message', 'User saved successfully.', 'success');
      } else {
        Swal.fire('Error', res.message, 'error');
      }
    }, err => {
      this.spinner.hide();
      Swal.fire('Error', 'Error occured. Please try again...', 'error');
    });
  }


  activateSeller() {
    this.spinner.show();
    const user = this.user;
    user.isActive = true;
    user.modifiedBy = 'admin';
    if (this.activateSub != null) {
      this.activateSub.unsubscribe();
    }
    this.activateSub = this.usersService.updateUser(user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        alert('Logsitic Partner activated successfully.');
        this.bindUser();
      } else {
        alert(res.message);
      }
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  deactivateSeller() {
    this.spinner.show();
    const user = this.user;
    user.isActive = false;
    user.modifiedBy = 'admin';
    if (this.deactivateSub != null) {
      this.deactivateSub.unsubscribe();
    }
    this.deactivateSub = this.usersService.updateUser(user).subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        alert('Logsitic Partner deactivated successfully.');
        this.bindUser();
      } else {
        alert(res.message);
      }
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  ngOnDestroy() {
    if (this.activateSub != null) {
      this.activateSub.unsubscribe();
    }
    if (this.deactivateSub != null) {
      this.deactivateSub.unsubscribe();
    }
    this.userSub.unsubscribe();
  }


  showLightbox(index) {
    this.selectedImageIndex = index;
    this.showFlag = true;
  }

  closeEventHandler() {
    this.showFlag = false;
    this.currentIndex = -1;
  }

  showCashCollection() {
    this.spinner.show();
    this.cashInflowService.getCashCollectionByUser(this.activatedRoute.snapshot.params.username,
      this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'))
      .subscribe(res => {
        this.spinner.hide();
        this.grossCashCollection = res.grossCashCollection;
        this.expenses = res.expenses;
        this.netCashCollection = res.netCashCollection;
      }, err => {
        this.spinner.hide();
        alert('Error occured. Please try again...');
      });
  }

  showRevenue() {
    this.spinner.show();
    this.expensesService.getExpensesByLogisticPartner(this.activatedRoute.snapshot.params.username,
      this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'))
      .subscribe(res => {
        this.spinner.hide();
        this.revenue = res.revenue;
        this.userExpenses = res.expenses;
        this.profit = res.profit;
      }, err => {
        this.spinner.hide();
        alert('Error occured. Please try again...');
        console.log('Error: ', err);
      });
  }
}
