import { ActivatedRoute } from '@angular/router';
import { ProductAttributesService } from './../../product-attributes.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-product-attributes',
  templateUrl: './product-attributes.component.html',
  styleUrls: ['./product-attributes.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ProductAttributesComponent implements OnInit, OnDestroy {
  baseApiUrl = BaseApiService.baseApiUrl;
  productAttributes: any[] = [];
  productAttributesSub: Subscription;
  deleteAttributeSub: Subscription = null;
  productid: number;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.productAttributes);
  displayedColumns: string[] = ['attributeName', 'attributeValue', 'actionEdit', 'actionDelete'];

  constructor(private productAttributesService: ProductAttributesService,
              private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.productid = +this.activatedRoute.snapshot.params.id;
    this.bindProductAttributes();
  }

  bindProductAttributes() {
    this.spinner.show();
    this.productAttributesSub = this.productAttributesService.getAttributesForProduct(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.productAttributes = res;
      this.dataSource = new MatTableDataSource<any>(this.productAttributes);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  deleteProductAttribute(id) {
    const deleted = confirm('Are you sure to delete this attribute?');
    if (deleted) {
      this.spinner.show();
      this.deleteAttributeSub = this.productAttributesService.deleteAttribute(id).subscribe(res => {
        this.spinner.hide();
        alert('Product attribute deleted successfully.');
        this.bindProductAttributes();
      }, err => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }

  }
  
  ngOnDestroy() {
    if (this.deleteAttributeSub != null) {
      this.deleteAttributeSub.unsubscribe();
    }
    this.productAttributesSub.unsubscribe();
  }

}
