import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ColorsService } from 'src/app/colors.service';
import { Color } from 'src/app/models';

@Component({
  selector: 'app-edit-color',
  templateUrl: './edit-color.component.html',
  styleUrls: ['./edit-color.component.scss']
})
export class EditColorComponent implements OnInit {

  color: Color;
  form: FormGroup;
  addColorSub: Subscription;
  constructor(private colorsService: ColorsService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindColor();
  }

  bindColor() {
    this.spinner.show();
    this.colorsService.getColor(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.color = res;
      this.spinner.hide();
      this.form.patchValue({
        colorName: res.colorName,
        colorValue: res.colorValue
      });
    }, err => {
      alert('Error occured. Please try again...');
    });
  }

  createForm() {
    this.form = this.fb.group({
      colorName: ['', Validators.required],
      colorValue: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
  
  saveColor() {

    this.spinner.show();
    this.color.colorName = this.form.value.colorName;
    this.color.colorValue = this.form.value.colorValue;
    this.addColorSub = this.colorsService.updateColor(this.color).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/admin/colors']);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.addColorSub != null) {
      this.addColorSub.unsubscribe();
    }
  }

}
