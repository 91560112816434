import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SellerPayment, User } from 'src/app/models';
import { SellerPaymentsService } from 'src/app/seller-payments.service';

@Component({
  selector: 'app-seller-payments',
  templateUrl: './seller-payments.component.html',
  styleUrls: ['./seller-payments.component.scss']
})
export class SellerPaymentsComponent implements OnInit {

  currentPage = 1;
  pageSize = 10;
  totalItems = 0;
  businessName = '';
  sortBy = 'dateFrom';
  direction = 'desc';
  form: FormGroup;
  fromDate : Date;
  toDate: Date;
  paymentStatus = '';
  sellerPayments: any[] = [];
  Paid = false;
  Unpaid = false;
  All = true;
  filteredStatus = '';
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<SellerPayment>(this.sellerPayments);
  displayedColumns: string[] = ['businessName', 'fromDate', 'toDate', 'amount', 'paymentStatus', 'paymentType',
  'referenceNumber', 'actionDetails', 'actionReceipt', 'actionReceiptCustomOrders'];
  constructor(private sellerPaymentService: SellerPaymentsService,
              private datePipe: DatePipe,
              private fb: FormBuilder,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      fromDate: [''],
      toDate: ['']
    });
    this.toDate = new Date();
    const d = new Date();
    d.setDate(this.toDate.getDate() - 6);
    this.fromDate = d;
    this.initVals();
    this.bindSellerPayments();
  }

  initVals() {
    this.currentPage = 1;
    this.totalItems = 0;
    this.sellerPayments = [];
  }

  filter(val: string) {
    this.initVals();
    this.filteredStatus = val;
    switch (val) {
      case 'Paid':
        this.Paid = true;
        this.Unpaid = false;
        this.All = false;
        break;
      case 'Unpaid':
        this.Paid = false;
        this.Unpaid = true;
        this.All = false;
        break;
      case 'All':
        this.Paid = false;
        this.Unpaid = false;
        this.All = true;
        this.filteredStatus = '';
        break;
    }
    this.bindSellerPayments();
  }

  bindSellerPayments() {
    this.spinner.show();
    this.sellerPaymentService.getSellerPaymentsByDateRange(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
     this.datePipe.transform(this.toDate, 'MM/dd/yyyy'), this.filteredStatus, this.businessName,
     this.currentPage - 1, this.pageSize)
    .subscribe(res => {
      this.spinner.hide();
      this.sellerPayments = res.records;
      this.sellerPayments.forEach(sellerPayment => {
        sellerPayment.isEditing = false;
      });
      this.totalItems = res.totalItems;
      this.dataSource = new MatTableDataSource<SellerPayment>(this.sellerPayments);
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  saveSellerPayments() {
    this.spinner.show();
    this.sellerPaymentService.saveSellerPayments(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
    this.datePipe.transform(this.toDate, 'MM/dd/yyyy'))
    .subscribe(res => {
      this.spinner.hide();
      if(res.added) {
        this.bindSellerPayments();
      }
      alert(res.message);
    }, err => {
      this.spinner.hide();
      if (err.status === 401) {
        this.router.navigateByUrl('/');
      } else {
        alert('Error occured. Please try again...' + err.statusText);
      }
    });
  }

  updateSellerPayment(sellerPayment: any) {
    console.log('Seller Payment: ', sellerPayment);
    this.spinner.show();
    this.sellerPaymentService.updateSellerPayment(sellerPayment)
    .subscribe(res => {
      this.spinner.hide();
      if (res.added) {
        sellerPayment.isEditing = false;
        this.bindSellerPayments();
      }
      alert(res.message);
    }, err => {
      this.spinner.hide();
      if(err.status == 401) {
        this.router.navigateByUrl('/');
      } else {
        alert('Error occured. Please try again...');
      }
    });
  }

  downloadReceipt(item: any) {
    const fromDate = new Date(item.fromDate.toString());
    const toDate = new Date(item.toDate.toString());
    window.open("https://api.jhelumlogistics.com/Download/DownloadSellerPaymentReceipt?username=" +
        item.username +
        "&startDate=" +
        this.datePipe.transform(fromDate, 'MM/dd/yyyy') +
        "&endDate=" + this.datePipe.transform(toDate, 'MM/dd/yyyy'));
  }

  downloadReceiptWithoutCommission(item: any) {
    const fromDate = new Date(item.fromDate.toString());
    const toDate = new Date(item.toDate.toString());
    window.open("https://api.jhelumlogistics.com/Download/DownloadSellerPaymentReceiptWithoutCommission?username=" +
      item.username +
      "&startDate=" +
      this.datePipe.transform(fromDate, 'MM/dd/yyyy') +
      "&endDate=" + this.datePipe.transform(toDate, 'MM/dd/yyyy'))
  }

  downloadReceiptCustomOrders(item: any) {
    const fromDate = new Date(item.fromDate.toString());
    const toDate = new Date(item.toDate.toString());
    window.open("https://api.jhelumlogistics.com/Download/DownloadSellerPaymentReceiptCustomOrders?username=" +
        item.username +
        "&startDate=" +
        this.datePipe.transform(fromDate, 'MM/dd/yyyy') +
        "&endDate=" + this.datePipe.transform(toDate, 'MM/dd/yyyy'));
  }

  doFilterBusinessName(event) {
    this.businessName = event.target.value;
    this.bindSellerPayments();
  }

  doSorting(event) {
    this.sortBy = event.active;
    this.direction = event.direction;
    this.bindSellerPayments();
  }

  changePage(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.bindSellerPayments();
  }

}
