import { HomepageProductService } from './../../homepage-product.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseApiService } from 'src/app/base-api.service';
import { HomePageHeading, HomePageProduct } from 'src/app/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-homepage-products',
  templateUrl: './homepage-products.component.html',
  styleUrls: ['./homepage-products.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomepageProductsComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  homepageProducts: HomePageProduct[] = [];
  homePageProductsSub: Subscription;
  deleteProductSub: Subscription = null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<HomePageProduct>(this.homepageProducts);
  displayedColumns: string[] = ['productName', 'productOrder', 'actionEdit', 'actionDelete'];

  constructor(private homepageProductsService: HomepageProductService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindProducts();
  }

  bindProducts() {
    this.spinner.show();
    this.homePageProductsSub = this.homepageProductsService.getProductsInHeading(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.homepageProducts = res;
      console.log(this.homepageProducts);
      this.dataSource = new MatTableDataSource<HomePageProduct>(this.homepageProducts);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteProduct(id: number) {
    const deleted = confirm('Are you sure you want to delete this product in this heading?');
    if (deleted) {
      this.spinner.show();
      this.deleteProductSub = this.homepageProductsService.deleteHomePageProduct(id).subscribe(res => {
        alert('Product deleted successfully in this heading.');
        this.bindProducts();
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        console.log(error);
        this.spinner.hide();
      });
    }
  }

  onClickAddNewProduct() {
    this.router.navigate(['/admin/add-homepage-product/' + (+this.activatedRoute.snapshot.params.id)]);
  }

  ngOnDestroy() {
    if (this.deleteProductSub != null) {
      this.deleteProductSub.unsubscribe();
    }
    this.homePageProductsSub.unsubscribe();
  }

}
