import { SiteSetting } from './../../models';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SiteSettingsService } from 'src/app/site-settings.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { ChangeEvent } from '@ckeditor/ckeditor5-angular';
import { Subscription } from 'rxjs';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-site-settings',
  templateUrl: './site-settings.component.html',
  styleUrls: ['./site-settings.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SiteSettingsComponent implements OnInit, OnDestroy {
  public Editor = ClassicEditor;
  about: string;
  aboutUs: string;
  cancellationRefundPolicy: string;
  privacyPolicy: string;
  pricing: string;
  terms: string;
  ckeConfig: any;

  file: FileUploadModel;
  siteSettingImageUploaded = false;
  isInProgress = false;
  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;
  form: FormGroup;
  siteSetting: SiteSetting;
  siteSettingSub: Subscription;
  uploadFileSub: Subscription = null;
  saveSettingSub: Subscription = null;

  constructor(private siteSettingsService: SiteSettingsService, private router: Router,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute) {
    this.createForm();
  }

  ngOnInit(): void {
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true
    };

    this.spinner.show();
    this.siteSettingSub = this.siteSettingsService.getSettings().subscribe(res => {
      this.spinner.hide();
      this.siteSetting = res;
      if (res != null) {
        this.fileName = this.siteSetting.logoPath.substring(res.logoPath.lastIndexOf('/') + 1);
        this.form.patchValue({
          headerContactNo: res.headerContactNo,
          siteName: res.siteName,
          address: res.address,
          mapHtml: res.mapHtml,
          phoneNos: res.phoneNos,
          emails: res.emails,
          about: res.about,
          aboutUs: res.aboutUs,
          cancellationRefundPolicy: res.cancellationRefundPolicy,
          privacyPolicy: res.privacyPolicy,
          pricing: res.pricing,
          terms: res.terms
        });
        this.about = res.about;
      }
    });
  }

  createForm() {
    this.form = this.fb.group({
      headerContactNo: ['', Validators.required],
      siteName: ['', Validators.required],
      address: ['', Validators.required],
      mapHtml: ['', Validators.required],
      phoneNos: ['', Validators.required],
      emails: ['', Validators.required],
      about: ['', Validators.required],
      aboutUs: ['', Validators.required],
      cancellationRefundPolicy: ['', Validators.required],
      privacyPolicy: ['', Validators.required],
      pricing: ['', Validators.required],
      terms: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveSettings() {
    if (this.fileName == null) {
      alert('Please upload logo image and try again...');
      return;
    }
    if (this.siteSetting == null) {
      this.siteSetting = {
        headerContactNo: this.form.value.headerContactNo,
        logoPath: 'SiteSettings/' + this.fileName,
        siteName: this.form.value.siteName,
        address: this.form.value.address,
        mapHtml: this.form.value.mapHtml,
        phoneNos: this.form.value.phoneNos,
        emails: this.form.value.emails,
        about: this.about,
        aboutUs: this.aboutUs,
        cancellationRefundPolicy: this.cancellationRefundPolicy,
        privacyPolicy: this.privacyPolicy,
        pricing: this.pricing,
        terms: this.terms
      };

      this.spinner.show();
      this.saveSettingSub = this.siteSettingsService.addSiteSettings(this.siteSetting).subscribe(res => {
        alert('Settings saved successfully.');
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });

    } else {
      this.siteSetting.logoPath = 'SiteSettings/' + this.fileName;
      this.siteSetting.siteName = this.form.value.siteName;
      this.siteSetting.address = this.form.value.address;
      this.siteSetting.mapHtml = this.form.value.mapHtml;
      this.siteSetting.phoneNos = this.form.value.phoneNos;
      this.siteSetting.emails = this.form.value.emails;
      this.siteSetting.about = this.about;
      this.siteSetting.aboutUs = this.aboutUs;
      this.siteSetting.cancellationRefundPolicy = this.cancellationRefundPolicy;
      this.siteSetting.privacyPolicy = this.privacyPolicy;
      this.siteSetting.pricing = this.pricing;
      this.siteSetting.terms = this.terms;

      this.spinner.show();
      this.saveSettingSub = this.siteSettingsService.updateSiteSettings(this.siteSetting).subscribe(res => {
        alert('Settings saved successfully.');
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }


  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = () => {
      const file = fileUpload.files[0];
      const formData: FormData = new FormData();
      formData.append('uploadFile', file, file.name);
      this.file = {
        data: file, state: 'in',
        inProgress: false, progress: 0, canRetry: false, canCancel: true
      };
      this.uploadFile(formData);
    };
    fileUpload.click();
  }

  private uploadFile(formData: FormData) {
    this.uploadFileSub = this.siteSettingsService.uploadLogoImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        }
        this.fileName = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  public onChange({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.about = data;
  }

  public onChangeAboutUs({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.aboutUs = data;
  }

  public onChangeCancellation({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.cancellationRefundPolicy = data;
  }

  public onChangePrivacyPolicy({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.privacyPolicy = data;
  }
  public onChangePricing({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.pricing = data;
  }
  public onChangeTerms({ editor }: ChangeEvent) {
    const data = editor.getData();

    this.terms = data;
  }

  ngOnDestroy() {
    if (this.saveSettingSub != null) {
      this.saveSettingSub.unsubscribe();
    }
    if (this.uploadFileSub != null) {
      this.uploadFileSub.unsubscribe();
    }
    this.siteSettingSub.unsubscribe();
  }

}
