import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ShippingPincodesService } from 'src/app/shipping-pincodes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ShippingPincode } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-pincode',
  templateUrl: './edit-pincode.component.html',
  styleUrls: ['./edit-pincode.component.scss']
})
export class EditPincodeComponent implements OnInit, OnDestroy {

  shippingPincode: ShippingPincode = null;
  form: FormGroup;
  pincodeSub: Subscription;
  savePincodeSub: Subscription;
  constructor(private shippingPincodesService: ShippingPincodesService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.spinner.show();
    this.pincodeSub = this.shippingPincodesService.getShippingPincode(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.shippingPincode = res;
      this.form.patchValue(res);
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
    });
  }

  createForm() {
    this.form = this.fb.group({
      fromPincode: ['', Validators.required],
      toPinCode: ['', Validators.required],
      state: ['', Validators.required],
      district: ['', Validators.required],
      codAvailable: [''],
      onlinePaymentAvailable: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  savePincode() {
    this.spinner.show();

    const pincode = this.form.value;
    pincode.id = this.activatedRoute.snapshot.params.id;

    this.savePincodeSub = this.shippingPincodesService.updateShippingPincode(pincode).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/admin/pincodes']);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.pincodeSub.unsubscribe();
    this.savePincodeSub.unsubscribe();
  }

}
