import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HomePageHeading } from './models';

@Injectable({
  providedIn: 'root'
})
export class HomepageHeadingService {

  constructor(private http: HttpClient) { }

  /**
   * getAll
   */
  public getAll(): Observable<HomePageHeading[]> {
    const url = BaseApiService.baseApiUrl + 'api/homepageheading/getall';

    return this.http.get<HomePageHeading[]>(url);
  }

  /**
   * get
   */
  public get(id: number): Observable<HomePageHeading> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageHeading/Get/' + id;

    return this.http.get<HomePageHeading>(url);
  }

  /**
   * addHomePageHeading
   */
  public addHomePageHeading(homePageHeading: HomePageHeading): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/homepageheading/post';
    const data = {
      headingName: homePageHeading.headingName,
      headingOrder: homePageHeading.headingOrder
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});
    
    return this.http.post<boolean>(url, data, {headers});
  }

  /**
   * getHomePageProducts
   */
  public getHomePageProducts(): Observable<HomePageHeading[]> {
    const url = BaseApiService.baseApiUrl + 'api/homepageheading/GetHomePageProducts';

    return this.http.get<HomePageHeading[]>(url);
  }

  /**
   * updateHomePageHeading
   */
  public updateHomePageHeading(homePageHeading: HomePageHeading): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/homepageheading/put';
    const data = {
      id: homePageHeading.id,
      headingName: homePageHeading.headingName,
      headingOrder: homePageHeading.headingOrder
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<boolean>(url, data, {headers});
  }

  /**
   * delete
   */
  public delete(id: number): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageHeading/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<boolean>(url, {headers});
  }
}
