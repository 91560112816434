import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ProductAttributesService } from 'src/app/product-attributes.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ProductAttribute } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-product-attribute',
  templateUrl: './edit-product-attribute.component.html',
  styleUrls: ['./edit-product-attribute.component.scss']
})
export class EditProductAttributeComponent implements OnInit, OnDestroy {

  productAttribute: ProductAttribute;
  form: FormGroup;
  productAttributeSub: Subscription;
  saveProductAttributeSub: Subscription = null;
  constructor(private productAttributesService: ProductAttributesService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
               }

  ngOnInit(): void {
    this.bindAttribute();
  }

  bindAttribute() {
    this.spinner.show();
    this.productAttributeSub = this.productAttributesService.getProductAttribute(+this.activatedRoute.snapshot.params.id).subscribe( res => {
      this.productAttribute = res;
      this.spinner.hide();
      this.form.patchValue({
        color : res.color,
        size : res.size,
        dimensions: res.dimensions
      });
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  createForm() {
    this.form = this.fb.group({
      attributeName: ['Size', Validators.required],
      attributeValue: ['', Validators.required],
      dimensions: ''
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveProductAttribute() {
    this.spinner.show();

    this.productAttribute.color = this.form.value.color;
    this.productAttribute.size = this.form.value.size;
    this.productAttribute.dimensions = this.form.value.dimensions;

    this.saveProductAttributeSub = this.productAttributesService.updateAttribute(this.productAttribute).subscribe(res => {
      alert('Product Attribute saved successfully.');
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
      alert('Error occured. Please try again...');
    });
  }

  ngOnDestroy() {
    if (this.saveProductAttributeSub != null) {
      this.saveProductAttributeSub.unsubscribe();
    }
    this.productAttributeSub.unsubscribe();
  }

}
