import { SubcategoryService } from './../../subcategory.service';
import { SubCategory } from './../../models';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { BaseApiService } from 'src/app/base-api.service';
import { Category } from 'src/app/models';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { CategoryService } from 'src/app/category.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-subcategories',
  templateUrl: './subcategories.component.html',
  styleUrls: ['./subcategories.component.scss']
})
export class SubcategoriesComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  subcategories: SubCategory[] = [];
  subcategoriesSub: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<SubCategory>(this.subcategories);
  displayedColumns: string[] = ['subcategoryName', 'subcategoryOrder', 'actionEdit', 'actionDelete'];

  constructor(private subCategoryService: SubcategoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindSubCategories();
  }

  bindSubCategories() {
    this.spinner.show();
    this.subcategoriesSub = this.subCategoryService.getByCategory(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.subcategories = res;
      this.dataSource = new MatTableDataSource<SubCategory>(this.subcategories);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  deleteSubCategory(id: number) {
    const deleted = confirm('Are you sure you want to delete this Subcategory?');
    if (deleted) {
      this.spinner.show();
      this.subCategoryService.deleteSubCategory(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert(res.message);
          this.bindSubCategories();
        } else {
          alert(res.message);
        }
      }, error => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
    }
  }

  onClickNewSubCategory() {
    this.router.navigate(['/admin/add-subcategory/' + (+this.activatedRoute.snapshot.params.id)]);
  }

  ngOnDestroy() {
    this.subcategoriesSub.unsubscribe();
  }

}
