import { HomepageHeadingService } from './../../homepage-heading.service';
import { HomePageHeading } from './../../models';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-homepage-headings',
  templateUrl: './homepage-headings.component.html',
  styleUrls: ['./homepage-headings.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class HomepageHeadingsComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  homepageHeadings: HomePageHeading[] = [];
  homapgeHeadingsSub: Subscription;
  deleteHeadingSub: Subscription = null;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<HomePageHeading>(this.homepageHeadings);
  displayedColumns: string[] = ['headingName', 'headingOrder', 'actionProducts', 'actionEdit', 'actionDelete'];

  constructor(private homepageHeadingService: HomepageHeadingService,
              private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindHeadings();
  }

  bindHeadings() {
    this.spinner.show();
    this.homapgeHeadingsSub = this.homepageHeadingService.getAll().subscribe(res => {
      this.homepageHeadings = res;
      this.dataSource = new MatTableDataSource<HomePageHeading>(this.homepageHeadings);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteHeading(id: number) {
    const deleted = confirm('Are you sure you want to delete this heading?');
    if (deleted) {
      this.spinner.show();
      this.deleteHeadingSub = this.homepageHeadingService.delete(id).subscribe(res => {
        alert('Heading deleted successfully.');
        this.bindHeadings();
        this.spinner.hide();
      }, error => {
        alert('Error occured. Please try again...');
        console.log(error);
        this.spinner.hide();
      });
    }
  }

  ngOnDestroy() {
    if (this.deleteHeadingSub != null) {
      this.deleteHeadingSub.unsubscribe();
    }
    this.homapgeHeadingsSub.unsubscribe();
  }

}
