import { BaseApiService } from './base-api.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HomePageProduct } from './models';

@Injectable({
  providedIn: 'root'
})
export class HomepageProductService {

  constructor(private http: HttpClient) { }

  /**
   * getProductsInHeading
   */
  public getProductsInHeading(id: number): Observable<HomePageProduct[]> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageProduct/Get/' + id;

    return this.http.get<HomePageProduct[]>(url);
  }
  public getHomePageProduct(id: number): Observable<HomePageProduct> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageProduct/GetHomePageProduct/' + id;

    return this.http.get<any>(url);
  }

  public getHomePageProducts(id: number, pageNumber: number, pageSize: number,
    minPrice: number, maxPrice:number, color: string, size: string, sortBy: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageProduct/GetProductsInHeading?headingId=' + id + 
    '&pageNumber=' + pageNumber + '&pageSize=' + pageSize +
    '&minPrice=' + minPrice + '&maxPrice=' + maxPrice + 
    '&color=' + color + '&size=' + size + '&sortBy=' + sortBy;

    console.log(url);

    return this.http.get<any>(url);
  }

  /**
   * addHomePageProduct
   */
  public addHomePageProduct(homepageProduct: HomePageProduct): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageProduct/Post';

    const data = {
      headingID: homepageProduct.headingID,
      productOrder: homepageProduct.productOrder,
      productID: homepageProduct.productID
    };

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<boolean>(url, data, {headers});
  }
  public updateHomePageProduct(homepageProduct: HomePageProduct): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageProduct/Put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, homepageProduct, {headers});
  }

  /**
   * deleteHomePageProduct
   */
  public deleteHomePageProduct(id: number): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/HomePageProduct/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<boolean>(url, {headers});

  }
}
