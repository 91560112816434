import { ProductsService } from './../../products.service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseApiService } from 'src/app/base-api.service';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { HttpEventType } from '@angular/common/http';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class ProductsComponent implements OnInit, OnDestroy {

  totalCount = 0;
  currentPage = 1;
  pageSize = 10;
  baseApiUrl = BaseApiService.baseApiUrl;
  products: any[] = [];
  productsSub: Subscription;
  productName = '';
  categoryName = '';
  subcategoryName = '';
  sortBy = 'name';
  direction = 'asc';
  isInProgress = false;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<any>(this.products);
  displayedColumns: string[] = ['productName', 'category', 'subcategory', 'actionStock', 'actionEdit'];

  constructor(private productsService: ProductsService,
              private spinner: NgxSpinnerService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.bindProducts();
  }

  bindProducts() {
    const username = this.activatedRoute.snapshot.params.username;
    this.spinner.show();
    this.productsSub = this.productsService.getByUsername(username,this.productName,
      this.categoryName, this.subcategoryName,
      this.currentPage - 1,this.pageSize,
      this.sortBy, this.direction).subscribe(res => {
      this.products = res.products;
      this.totalCount = res.totalItems;
      this.dataSource = new MatTableDataSource<any>(this.products);
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.productsSub.unsubscribe();
  }

  doFilter(event) {
    this.productName = event.target.value;
    this.bindProducts();
  }

  doFilterCategory(event) {
    this.categoryName = event.target.value;
    this.bindProducts();
  }

  doFilterSubcategory(event) {
    this.subcategoryName = event.target.value;
    this.bindProducts();
  }

  doSorting(event) {
    this.sortBy = event.active;
    this.direction = event.direction;
    this.bindProducts();
  }

  changePage(event: PageEvent) {
    this.currentPage = event.pageIndex + 1;
    this.pageSize = event.pageSize;
    this.bindProducts();
  }

  exportToExcel() {

    // const title = 'Summary of orders from date dash __________ to date dash';
    // const header = ['Dated', 'Order No', 'Order By', 'Quantity', 'Price'];
    // const data = [
    //   [2007, 1, 'Adil ',  1267, 10],
    //   [2007, 2, 'Shabir ',  819, 6.5],
    //   [2007, 3, 'Javid ', 787, 6.2],
    //   [2007, 4, 'Yamin ',  720, 5.7],
    //   [2007, 5, 'Amit ',  691, 5.4],
    // ];
    // this.excelService.generateExcel('ordersummary', title, header, data);

    this.spinner.show();
    this.isInProgress = true;
    this.productsService.downloadProductReport(this.activatedRoute.snapshot.params.username,
      this.productName, this.categoryName, this.subcategoryName).subscribe(res => {
        this.spinner.hide();
        switch (res.type) {
          case HttpEventType.DownloadProgress:
            this.isInProgress = true;
            break;
          case HttpEventType.Response:
            this.isInProgress = false;
            const downloadedFile = new Blob([res.body]);
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'Products_' + this.activatedRoute.snapshot.params.username + '.xlsx';
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
  }

}
