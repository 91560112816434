import { Category } from './../../models';
import { CategoryService } from './../../category.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit, OnDestroy {

  form: FormGroup;
  addCategorySub: Subscription;
  file: FileUploadModel;
  isInProgress = false;
  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;
  imgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };
  constructor(private categoryService: CategoryService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      categoryName: ['', Validators.required],
      categoryOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.imgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];  
        
      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.imgValidations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG" ||
       mimeType == "image/svg+xml") ) {
        this.imgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      if (fileKb > 120) {
        this.imgValidations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;
        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.imgValidations.validDimension =  ( width !=  100|| height != 100 ) ? false : true;

          // console.log('Image W H = ', width, height);

          if (!this.imgValidations.validDimension || 
              !this.imgValidations.validSize ||
              !this.imgValidations.validType) {

                let errorMsg = "";
                if (!this.imgValidations.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.imgValidations.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.imgValidations.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile(formData);
          }
        };
      };


    };
    console.log('clicking fileupload....');
    fileUpload.click();
  }



  private uploadFile(formData: FormData) {
    this.categoryService.uploadCategoryImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message <= 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        }
        this.fileName = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  saveCategory() {
    if (this.fileName === '') {
      alert('Please upload the file first.');
      return;
    }

    this.spinner.show();
    const category: Category = {
      menuID: (+this.activatedRoute.snapshot.params.id),
      categoryName: this.form.value.categoryName,
      categoryOrder: this.form.value.categoryOrder,
      imagePath: 'Categories/' + this.fileName
    };
    this.addCategorySub = this.categoryService.addCategory(category).subscribe(added => {
      if (added) {
        alert('Category saved successfully.');
        this.router.navigate(['/admin/categories/' + (+this.activatedRoute.snapshot.params.id)]);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.addCategorySub != null) {
      this.addCategorySub.unsubscribe();
    }
  }

}
