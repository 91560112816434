import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { BaseApiService } from 'src/app/base-api.service';
import { ColorsService } from 'src/app/colors.service';
import { Color } from 'src/app/models';

@Component({
  selector: 'app-colors',
  templateUrl: './colors.component.html',
  styleUrls: ['./colors.component.scss']
})
export class ColorsComponent implements OnInit {

  baseApiUrl = BaseApiService.baseApiUrl;
  colors: Color[] = [];
  colorsSub: Subscription;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  dataSource = new MatTableDataSource<Color>(this.colors);
  displayedColumns: string[] = ['colorName', 'colorValue', 'actionEdit', 'actionDelete'];

  constructor(private colorsService: ColorsService,
    private router: Router,
    private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.bindColors();
  }

  bindColors() {
    this.spinner.show();
    this.colorsSub = this.colorsService.getColors().subscribe(res => {
      this.colors = res;
      this.dataSource = new MatTableDataSource<Color>(this.colors);
      this.dataSource.paginator = this.paginator;
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      console.log(error);
      this.spinner.hide();
    });
  }

  deleteColor(id: number) {
    const deleted = confirm('Are you sure you want to delete this Color?');
    if (deleted) {
      this.spinner.show();
      this.colorsService.deleteColor(id).subscribe(res => {
        this.spinner.hide();
        if (res.added) {
          alert(res.message);
          this.bindColors();
        } else {
          alert(res.message);
        }
      }, error => {
        this.spinner.hide();
        if (error instanceof HttpErrorResponse) {
          if (error.status == 401) {
            this.router.navigate(['/sign-in']);
          } else {
            alert('Error occured. Please try again...');
          }
        } else {
          alert('Error occured. Please try again...');
        }
      });
    }
  }

  ngOnDestroy() {
    this.colorsSub.unsubscribe();
  }

}
