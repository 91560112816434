import { CdkVirtualScrollViewport, ScrollDispatcher } from '@angular/cdk/scrolling';
import { DatePipe } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription, Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { BaseApiService } from 'src/app/base-api.service';
import { ExcelService } from 'src/app/excel.service';
import { Order } from 'src/app/models';
import { OrdersService } from 'src/app/orders.service';

@Component({
  selector: 'app-request-orders',
  templateUrl: './request-orders.component.html',
  styleUrls: ['./request-orders.component.scss']
})
export class RequestOrdersComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport, { static: false }) virtualScroll: CdkVirtualScrollViewport;
  form: FormGroup;
  baseApiUrl = BaseApiService.baseApiUrl;
  percentComplete = 0;
  isInProgress = false;
  orders: Order[] = [];
  ordersSub: Subscription;
  // @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  // dataSource = new MatTableDataSource<Order>(this.orders);
  // displayedColumns: string[] = ['orderID', 'customerName',
  //   'customerMobile', 'orderDate', 'orderStatus', 'paymentMethod', 'actionDetails'];

  pageNumber = 1;
  pageSize = 10;
  totalItems = 0;
  totalPages = 1;
  toDate: Date;
  fromDate: Date;

  Pending = false;
  Dispatched = false;
  Delivered = false;
  Returned = false;
  PaymentStarted = false;
  All = true;
  loading = true;
  filteredStatus = '';
  allOrdersFetched = false;
  orderId = 0;
  customerName = '';
  private unsubscribeAll$: Subject<void> = new Subject();
  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService,
    private scrollDispatcher: ScrollDispatcher,
    private changeDetector: ChangeDetectorRef,
    private router: Router,
    private ordersService: OrdersService, private datePipe: DatePipe,
    private excelService: ExcelService) {
    this.createForm();
  }







  exportToExcel() {
    this.spinner.show();
    this.isInProgress = true;
    this.ordersService.downloadRequestOrders(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'), this.filteredStatus).subscribe(res => {
        this.spinner.hide();
        switch (res.type) {
          case HttpEventType.DownloadProgress:
            this.isInProgress = true;
            this.percentComplete = Math.round((res.loaded / res.total) * 100);
            break;
          case HttpEventType.Response:
            this.isInProgress = false;
            this.percentComplete = 0;
            const downloadedFile = new Blob([res.body]);
            const a = document.createElement('a');
            a.setAttribute('style', 'display:none;');
            document.body.appendChild(a);
            a.download = 'Orders_' + this.form.value.fromDate + '_' + this.form.value.toDate + '_' + status + '.xlsx';
            a.href = URL.createObjectURL(downloadedFile);
            a.target = '_blank';
            a.click();
            document.body.removeChild(a);
            break;
        }
      }, err => {
        this.spinner.hide();
        console.log(err);
      });
  }


  setDefaultRage() {
    this.toDate = new Date();
    const d = new Date();
    d.setDate(this.toDate.getDate() - 10);
    this.fromDate = d;
  }
  ngOnInit(): void {
    this.setDefaultRage();
    this.initVals();
    this.getOrders(true);
  }

  initVals() {
    this.pageNumber = 1;
    this.totalItems = 0;
    this.totalPages = 1;
    this.orders = [];
    this.allOrdersFetched = false;
  }
  dateChanged() {
    this.initVals();
    this.getOrders(false);
  }

  filter(val: string) {
    this.initVals();
    this.filteredStatus = val;
    switch (val) {
      case 'Pending':
        this.Pending = true;
        this.Dispatched = false;
        this.Delivered = false;
        this.Returned = false;
        this.PaymentStarted = false;
        this.All = false;
        break;
      case 'Payment Started':
        this.Pending = false;
        this.PaymentStarted = true;
        this.Dispatched = false;
        this.Delivered = false;
        this.Returned = false;
        this.All = false;
        break;
      case 'Dispatched':
        this.Pending = false;
        this.Dispatched = true;
        this.Delivered = false;
        this.Returned = false;
        this.PaymentStarted = false;
        this.All = false;
        break;
      case 'Delivered':
        this.Pending = false;
        this.Dispatched = false;
        this.Delivered = true;
        this.Returned = false;
        this.PaymentStarted = false;
        this.All = false;
        break;
      case 'Returned':
        this.Pending = false;
        this.Dispatched = false;
        this.Delivered = false;
        this.PaymentStarted = false;
        this.Returned = true;
        this.All = false;
        break;
      case 'All':
        this.Pending = false;
        this.Dispatched = false;
        this.Delivered = false;
        this.Returned = false;
        this.PaymentStarted = false;
        this.All = true;
        this.filteredStatus = '';
        break;
    }
    this.getOrders(false);
  }

  exists(element) {
    let isExisting = false;
    for (let i = 0; i < this.orders.length; i++) {
      if (this.orders[i].id === element.id) {
        isExisting = true;
        break;
      }
    }
    return isExisting;
  }
  getOrders(firstCall) {

    this.spinner.show();

    this.ordersSub = this.ordersService.getRequestOrders(this.datePipe.transform(this.fromDate, 'MM/dd/yyyy'),
      this.datePipe.transform(this.toDate, 'MM/dd/yyyy'), this.filteredStatus,
      this.pageNumber - 1, this.pageSize, this.orderId, this.customerName).subscribe(res => {
        // this.orders = res.orders;
        this.totalItems = res.totalItems;
        this.orders = res.orders;


        this.spinner.hide();

      }, err => {
        alert('Error occured. Please try again...');
        this.spinner.hide();
      });
  }

  createForm() {
    this.form = this.fb.group({
      fromDate: ['', Validators.required],
      toDate: ['', Validators.required],
      status: ['']
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  showOrders() {
    if (this.totalItems === 0) {
      this.getOrders(true);
    } else {
      this.getOrders(false);
    }
  }

  navigateToOrderDetails(id: number) {
    // const navigationExtras: NavigationExtras = {
    //   queryParams: {'q': encodeURIComponent(btoa(String(id)))}
    // };
    this.router.navigate([]).then(result => {  window.open("/admin/request-order-details/" + id, '_blank'); });
  }

  ngOnDestroy() {
    if (this.ordersSub != null) {
      this.ordersSub.unsubscribe();
    }

    if (this.unsubscribeAll$ != null) {
      this.unsubscribeAll$.next();
      this.unsubscribeAll$.unsubscribe();
    }
  }

  // changePage(event: PageEvent) {
  //   this.pageNumber = event.pageIndex + 1;
  //   this.pageSize = event.pageSize;
  //   this.getOrders();
  // }


  ngAfterViewInit(): void {

  }

  getUpdatedOrders() {
    this.initVals();
    this.getOrders(false);
  }

  public onPageChanged(event) {
    this.pageNumber = event;
    this.getOrders(false);
    window.scrollTo(0, 0);
  }

}
