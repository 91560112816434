import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { HomepageHeadingService } from 'src/app/homepage-heading.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HomePageHeading } from 'src/app/models';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-homepage-heading',
  templateUrl: './edit-homepage-heading.component.html',
  styleUrls: ['./edit-homepage-heading.component.scss']
})
export class EditHomepageHeadingComponent implements OnInit, OnDestroy {

  homepageHeading: HomePageHeading = null;
  form: FormGroup;
  homePageHeadingSub: Subscription;
  saveHomePageHeadingSub: Subscription;
  constructor(private homepageHeadingService: HomepageHeadingService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
    this.bindHeading();
  }

  bindHeading() {
    this.spinner.show();
    this.homePageHeadingSub = this.homepageHeadingService.get(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.homepageHeading = res;
      this.form.patchValue({
        headingName: res.headingName,
        headingOrder: res.headingOrder
      });
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.homepageHeading = null;
    });
  }

  createForm() {
    this.form = this.fb.group({
      headingName: ['', Validators.required],
      headingOrder: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  saveHeading() {
    this.spinner.show();

    this.homepageHeading.headingName = this.form.value.headingName;
    this.homepageHeading.headingOrder = this.form.value.headingOrder;

    this.saveHomePageHeadingSub = this.homepageHeadingService.updateHomePageHeading(this.homepageHeading).subscribe(added => {
      if (added) {
        alert('Homepage Heading saved successfully.');
        this.router.navigate(['/admin/homepage-headings']);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    this.homePageHeadingSub.unsubscribe();
    if (this.saveHomePageHeadingSub != null) {
      this.saveHomePageHeadingSub.unsubscribe();
    }
  }

}
