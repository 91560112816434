import { ProductAttribute } from './models';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProductAttributesService {

  constructor(private http: HttpClient) { }

  public getAttributesForProduct(productId: number): Observable<ProductAttribute[]> {
    const url = BaseApiService.baseApiUrl + 'api/ProductAttributes/GetByProduct/' + productId;

    return this.http.get<ProductAttribute[]>(url);
  }

  public getAttributesByAttributeName(productId: number, attributeName: string): Observable<ProductAttribute[]> {
    const url = BaseApiService.baseApiUrl + 'api/ProductAttributes/GetProductAttributesByAttributeName?productId=' +
                productId + '&attributeName=' + attributeName;

    return this.http.get<ProductAttribute[]>(url);
  }

  public getProductAttribute(attributeId: number): Observable<ProductAttribute> {
    const url = BaseApiService.baseApiUrl + 'api/ProductAttributes/GetProductAttribute/' +
                attributeId;

    return this.http.get<ProductAttribute>(url);
  }

  public addAttribute(productAttribute: ProductAttribute): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ProductAttributes/AddAttribute';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, productAttribute, {headers});
  }

  public updateAttribute(productAttribute: ProductAttribute): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/ProductAttributes/UpdateAttribute';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<boolean>(url, productAttribute, {headers});
  }

  public deleteAttribute(id: number): Observable<boolean> {
    const url = BaseApiService.baseApiUrl + 'api/ProductAttributes/DeleteAttribute/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<boolean>(url, {headers});
  }
}
