import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SlidersService } from 'src/app/sliders.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Category, Menu, Slider, SubCategory, TopCategory } from 'src/app/models';
import { Subscription } from 'rxjs';
import { TopCategoryService } from 'src/app/top-category.service';
import { CategoryService } from 'src/app/category.service';
import { MenuService } from 'src/app/menu.service';
import { SubcategoryService } from 'src/app/subcategory.service';
import { BaseApiService } from 'src/app/base-api.service';
import Swal from 'sweetalert2';
import { FileUploadModel } from 'src/app/models/models';

@Component({
  selector: 'app-edit-topcategory',
  templateUrl: './edit-topcategory.component.html',
  styleUrls: ['./edit-topcategory.component.scss'],
  animations: [
    trigger('fadeInOut', [
      state('in', style({ opacity: 100 })),
      transition('* => void', [
        animate(300, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class EditTopCategoryComponent implements OnInit, OnDestroy {

  baseApiUrl = BaseApiService.baseApiUrl;
  previvewImgURL: any;
  text = 'Select Top Category Image';
  menus: Menu[];
  categories: Category[];
  subcategories: SubCategory[];
  menusSub: Subscription;
  categoriesSub: Subscription;
  subcategoriesSub: Subscription;
  file: FileUploadModel;
  sliderImageUploaded = false;
  isInProgress = false;
  fileName = '';
  uploadResponse = { status: '', message: '', filePath: '' };
  error: string;
  topCategoryForm: FormGroup;
  topCategory: TopCategory;
  topCategorySub: Subscription;
  uploadFileSub: Subscription = null;
  savetopCategorySub: Subscription = null;

  imgValidations = {
    uploadAttempted: false,
    validDimension: true,
    validType: true,
    validSize: true
  };

  constructor(private slidersService: SlidersService, private router: Router,
    private spinner: NgxSpinnerService,
    private topCategoryService: TopCategoryService,
    private menuService: MenuService,
    private categoryService: CategoryService,
    private subcategoryService: SubcategoryService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute) {
    this.createForm();
  }

  ngOnInit(): void {
    this.spinner.show();
    this.topCategorySub = this.topCategoryService.getTopCategory(+this.activatedRoute.snapshot.params.id).subscribe(res => {
      this.topCategory = res;
      this.topCategoryForm.patchValue({
        displayOrder: this.topCategory.displayOrder,
        displayName: this.topCategory.displayName,
      });
      this.fileName = this.topCategory.imagePath.substring(res.imagePath.lastIndexOf('/') + 1);
      this.spinner.hide();
      this.bindMenus();
    });
  }

  bindMenus() {
    this.spinner.show();
    this.menusSub = this.menuService.getMenus().subscribe(res => {
      this.menus = res;
      this.spinner.hide();
      this.bindCategories(this.topCategory.menuID);
    });
  }

  bindCategories(menuId: number) {
    this.spinner.show();
    this.categoriesSub = this.categoryService.getByMenu(menuId).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
      this.bindSubCategories(this.topCategory.categoryID);
    });
  }

  bindSubCategories(categoryId: number) {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(categoryId).subscribe(res => {
      this.subcategories = res;
      this.topCategoryForm.patchValue({
        menu: this.topCategory.menuID,
        category: this.topCategory.categoryID,
        subcategory: this.topCategory.subcategoryID
      });

      this.spinner.hide();
    });
  }

  createForm() {
    this.topCategoryForm = this.fb.group({
      displayOrder: ['', Validators.required],
      displayName: ['', Validators.required],
      menu: ['', Validators.required],
      category: ['', Validators.required],
      subcategory: ['', Validators.required],
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.topCategoryForm.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }

  onClick() {
    const fileUpload = document.getElementById('fileUpload') as HTMLInputElement;
    fileUpload.onchange = (event: any) => {
      if (fileUpload.files.length === 0) {
        // nothing choosed to upload
        return;
      }

      this.imgValidations.uploadAttempted = true;
      const file = fileUpload.files[0];  
        
      const mimeType = file.type;
      //  console.log(file.type);   
       // check it is a image format..
       if (mimeType.match(/image\/*/) == null) {          
        this.imgValidations.validType = false;
       } 

       // check it is among allowed image formats
       if (!(mimeType == "image/png" || "image/PNG" ||
       mimeType == "image/jpg" || "image/JPG" ||
       mimeType == "image/jpeg" || "image/JPEG" ||
       mimeType == "image/svg+xml") ) {
        this.imgValidations.validType = false;
      }

      // check file size 200kb 
      const fsize =  file.size; 
      const fileKb = Math.round((fsize / 1024)); 
      console.log(fileKb);
      if (fileKb > 120) {
        this.imgValidations.validSize = false;
      }
      //validate dimensions 
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = () => {
          const height = img.naturalHeight;
          const width = img.naturalWidth;
          // 1280 x 782
          this.imgValidations.validDimension =  ( width !=  100 || height != 100 ) ? false : true;
          // this.imgValidations.validDimension = true;
          // console.log('Image W H = ', width, height);

          if (!this.imgValidations.validDimension || 
              !this.imgValidations.validSize ||
              !this.imgValidations.validType) {

                let errorMsg = "";
                if (!this.imgValidations.validDimension) {
                  errorMsg += "<div>Invalid file dimensions</div>";
                }
                if (!this.imgValidations.validSize) {
                  errorMsg += "<div>Invalid file size</div>";
                }
                if (!this.imgValidations.validType) {
                  errorMsg += "<div>Invalid file type</div>";
                }
             
              Swal.fire('Error', errorMsg, 'error');
            // console.log('returning one or more validations failed.........');
            return;
          } else  {
            console.log('setting form data');
            this.previvewImgURL = reader.result;
            const formData: FormData = new FormData();
            formData.append('uploadFile', file, file.name);
            // console.log(formData);
            this.file = {
              data: file, state: 'in',
              inProgress: false, progress: 0, canRetry: false, canCancel: true
            };
            this.uploadFile(formData);
          }
        };
      };


    };
    console.log('clicking fileupload....');
    fileUpload.click();
  }


  private uploadFile(formData: FormData) {
    this.uploadFileSub = this.topCategoryService.uploadTopCategoryImage(formData).subscribe(
      (res) => {
        if (typeof res === 'object' && res.message < 100) {
          this.uploadResponse = res;
          this.isInProgress = true;
        } else {
          this.isInProgress = false;
        }
        this.fileName = this.file.data.name;
      },
      (err) => {
        this.error = err;
      }
    );


  }

  onMenuSelected() {
    this.spinner.show();
    this.categoryService.getByMenu(this.topCategoryForm.value.menu).subscribe(res => {
      this.categories = res;
      this.spinner.hide();
    });
  }

  onCategorySelected() {
    this.spinner.show();
    this.subcategoriesSub = this.subcategoryService.getByCategory(this.topCategoryForm.value.category).subscribe(res => {
      this.subcategories = res;
      this.spinner.hide();
    });
  }

  saveTopCategory() {
    if (this.fileName === '') {
      alert('Please upload the file first.');
      return;
    }
    this.spinner.show();

    this.topCategory.displayOrder = this.topCategoryForm.value.displayOrder;
    this.topCategory.imagePath = 'TopCategory/' + this.fileName;
    this.topCategory.displayName = this.topCategoryForm.value.displayName;
    this.topCategory.menuID = this.topCategoryForm.value.menu;
    this.topCategory.categoryID = this.topCategoryForm.value.category;
    this.topCategory.subcategoryID = this.topCategoryForm.value.subcategory;

    this.savetopCategorySub = this.topCategoryService.updateTopCategory(this.topCategory).subscribe(added => {
      if (added) {
        alert('Top Category saved successfully.');
        this.router.navigate(['/admin/topcategories']);
      } else {
        alert('Error occured. Please try again...');
      }
      this.spinner.hide();
    }, err => {
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.uploadFileSub != null) {
      this.uploadFileSub.unsubscribe();
    }
    if (this.savetopCategorySub != null) {
      this.savetopCategorySub.unsubscribe();
    }
    this.topCategorySub.unsubscribe();

    this.menusSub.unsubscribe();
    this.categoriesSub.unsubscribe();
    this.subcategoriesSub.unsubscribe();
  }

}

