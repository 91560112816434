import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import { ColorsService } from 'src/app/colors.service';
import { Category, Color } from 'src/app/models';

@Component({
  selector: 'app-add-color',
  templateUrl: './add-color.component.html',
  styleUrls: ['./add-color.component.scss']
})
export class AddColorComponent implements OnInit {

  form: FormGroup;
  addColorSub: Subscription;
  constructor(private colorsService: ColorsService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) {
                this.createForm();
              }

  ngOnInit(): void {
  }

  createForm() {
    this.form = this.fb.group({
      colorName: ['', Validators.required],
      colorValue: ['', Validators.required]
    });
  }

  // retrieve a FormControl
  getFormControl(name: string) {
    return this.form.get(name);
  }

  isValid(name: string) {
    const e = this.getFormControl(name);
    return e && e.valid;
  }

  isChanged(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched);
  }

  hasError(name: string) {
    const e = this.getFormControl(name);
    return e && (e.dirty || e.touched) && !e.valid;
  }
  
  saveColor() {

    this.spinner.show();
    const color: Color = {
      colorName: this.form.value.colorName,
      colorValue: this.form.value.colorValue
    };
    this.addColorSub = this.colorsService.addColor(color).subscribe(res => {
      if (res.added) {
        alert(res.message);
        this.router.navigate(['/admin/colors']);
      } else {
        alert(res.message);
      }
      this.spinner.hide();
    }, error => {
      alert('Error occured. Please try again...');
      this.spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.addColorSub != null) {
      this.addColorSub.unsubscribe();
    }
  }

}
