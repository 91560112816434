import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgo implements PipeTransform {

  constructor() { }

  transform(dateStr: string): string {
    // console.log('dateStr', dateStr);
    const reviewDate = Math.round(new Date(dateStr).getTime() / 1000);
    const currentDate = Math.round(new Date().getTime() / 1000);
    const result = this.convertSeconds(currentDate - reviewDate);
    // {y: 1, mon: 5, w: 1, d: 2, h: 1, m: 0, s: 5}

    // console.log(result);
    if (result.y > 0) {
      if (result.mon >= 11) {
        return `${(result.y + 1)} years ago`;
      } else {
        return `${result.y} ${result.y === 1 ? 'year' : 'years'} ago`;
      }
    } else if (result.mon > 0) {
      if (result.w >= 3) {
        return `${(result.mon + 1)} months ago`;
      } else {
        return `${result.mon} ${result.mon === 1 ? 'month' : 'months'} ago`;
      }
    } else if (result.w > 0) {
      if (result.d >= 6) {
        return `${(result.w + 1)} weeks ago`;
      } else {
        return `${result.w} ${result.w === 1 ? 'week' : 'weeks'} ago`;
      }
    } else if (result.d > 0) {
      if (result.h >= 23) {
        return `${(result.d + 1)} days ago`;
      } else {
        return `${result.d} ${result.d === 1 ? 'day' : 'days'} ago`;
      }
    } else if (result.h > 0) {
      if (result.m >= 50) {
        return `${(result.h + 1)} hours ago`;
      } else {
        return `${result.h} ${result.h === 1 ? 'hour' : 'hours'} ago`;
      }
    } else if (result.m > 0) {
      if (result.m >= 50) {
        return `${(result.m + 1)} minutes ago`;
      } else {
        return `${result.m} ${result.m === 1 ? 'minute' : 'minutes'} ago`;
      }
    } else {
      return `few seconds ago`;
    }
    // console.log(`${current} - ${reviewDate} ; ${current > reviewDate} : `, current - reviewDate);

  }


  convertSeconds(seconds) {
    // tslint:disable-next-line:one-variable-per-declaration
    let y, mon, w, d, h, m, s;

    // if mmiliseconds are passed
    // seconds = Math.floor(ms / 1000);
    m = Math.floor(seconds / 60);
    s = seconds % 60;

    h = Math.floor(m / 60);
    m = m % 60;

    d = Math.floor(h / 24);
    h = h % 24;

    w = Math.floor(d / 7);
    d = d % 7;

    // month = 28 days (approx)
    mon = Math.floor(w / 4);
    w = w % 4;

    y = Math.floor(mon / 12);
    mon = mon % 12;

    return { y, mon, w, d, h, m, s };
  }
}

