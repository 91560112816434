import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base-api.service';
import { ShippingPincodeSlab } from './models';

@Injectable({
  providedIn: 'root'
})
export class ShippingPincodeSlabService {

  constructor(private http: HttpClient) { }

  public getSlabs(pincodeId: Number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincodeSlab/GetSlabs?pincodeId=' + pincodeId;

    return this.http.get<any>(url);
  }

  public getSlab(id: number): Observable<ShippingPincodeSlab> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincodeSlab/GetSlab/' + id;

    return this.http.get<ShippingPincodeSlab>(url);
  }

  public addSlab(shippingPincodeSlab: any): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincodeSlab/Post';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.post<any>(url, shippingPincodeSlab, {headers});
  }

  public updateSlab(shippingPincodeSlab: any): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincodeSlab/Put';

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.put<any>(url, shippingPincodeSlab, {headers});
  }

  public deleteSlab(id: number): Observable<any> {
    const url = BaseApiService.baseApiUrl + 'api/ShippingPincodeSlab/Delete/' + id;

    const token = JSON.parse(localStorage.getItem('token')).access_token;

    const headers = new HttpHeaders({Authorization: 'Bearer ' + token});

    return this.http.delete<any>(url, {headers});
  }
}
